<template>
  <div class="overview-item">
    <div class="overview-title">
      <span>{{ overview.title }}</span>
    </div>
    <div class="overview-amount" v-if="!overview.isBRC20">
      {{ overview.amount }}
    </div>
    <div class="overview-status" v-else>
      <div class="process-wrap">
        <div class="process-bg"></div>
        <div
          class="process-bar"
          :style="{ width: overview.percent + '%' }"
        ></div>
        <div class="process-percent">{{ overview.percent }}%</div>
      </div>
      <div
        class="mint-button"
        :class="{ disabled: overview.percent == 100 }"
        @click="goUniSat"
      >
        {{ overview.btnText }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    overview: {
      type: Object,
      default: Object,
    },
  },
  methods: {
    goUniSat() {
      window.open(`https://hk.unisat.io/brc20/${this.overview.symbol}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.overview-item {
  padding: 0.22rem 0.32rem;
  background: var(--bg-10);
  border-radius: 0.16rem;
  .overview-title {
    display: flex;
    span {
      font-size: 0.2rem;
      font-family: PingFang SC, PingFang SC-Regular;
      color: var(--gray-11);
    }
  }
  .overview-amount {
    margin-top: 0.08rem;
    font-size: 0.26rem;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: var(--gray-1);
    word-break: break-all;
  }
  .overview-status {
    margin-top: 0.06rem;
    display: flex;
    align-items: center;
    .process-wrap {
      flex: 1;
      position: relative;
      .process-bg {
        height: 0.36rem;
        background: var(--process-bg);
        border-radius: 0.3rem;
      }
      .process-bar {
        position: absolute;
        left: 0;
        top: 0;
        min-width: 0.2rem;
        height: 0.36rem;
        background: #2980fe;
        border-radius: 0.3rem;
      }
      .process-percent {
        position: absolute;
        left: 0.11rem;
        top: 50%;
        transform: translateY(-50%);
        font-size: 0.18rem;
        font-family: PingFang SC, PingFang SC-Medium;
        color: #ffffff;
      }
    }
    .mint-button {
      height: 0.36rem;
      line-height: 0.36rem;
      margin-left: 0.06rem;
      background: #2980fe;
      border-radius: 0.3rem;
      font-size: 0.2rem;
      font-family: PingFang SC, PingFang SC-Medium;
      color: #ffffff;
      padding: 0 0.05rem;
      cursor: pointer;
      &.disabled {
        background: var(--finish-bg);
        pointer-events: none;
      }
    }
  }
}
@media screen and (min-width: 767px) {
  .overview-item {
    padding: 0.13rem 0.2rem;
    .overview-title {
      span {
        font-size: 0.14rem;
        transform: scale(0.9);
        margin-left: -0.03rem;
      }
    }
    .overview-amount {
      margin-top: 0.05rem;
      font-size: 0.18rem;
    }
    .overview-status {
      margin-top: 0.06rem;
      .process-wrap {
        .process-bg {
          height: 0.2rem;
          background: #194585;
          border-radius: 0.1rem;
        }
        .process-bar {
          position: absolute;
          left: 0;
          top: 0;
          min-width: 0.2rem;
          height: 0.2rem;
          background: #2980fe;
          border-radius: 0.1rem;
        }
        .process-percent {
          position: absolute;
          left: 0.08rem;
          top: 50%;
          transform: translateY(-50%);
          font-size: 0.12rem;
          font-family: PingFang SC, PingFang SC-Medium;
          color: #ffffff;
        }
      }
      .mint-button {
        height: 0.2rem;
        line-height: 0.2rem;
        margin-left: 0.06rem;
        border-radius: 0.12rem;
        font-size: 0.11rem;
        font-family: PingFang SC, PingFang SC-Medium;
        color: #ffffff;
        padding: 0 0.12rem;
        cursor: pointer;
        &.disabled {
          background: var(--finish-bg);
          pointer-events: none;
        }
      }
    }
  }
}
@media screen and (min-width: 1400px) {
  .overview-item {
    padding: 0.13rem 0.2rem;
    .overview-title {
      span {
        font-size: 0.12rem;
        transform: scale(0.9);
        margin-left: -0.03rem;
      }
    }
    .overview-amount {
      margin-top: 0.05rem;
      font-size: 0.16rem;
    }
  }
}
</style>
