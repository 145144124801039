<template>
  <div class="content base-content release-content security-content">
    <div class="text title">
      <span>{{ $t('securityInfo') }}</span>
      <span class="give-up-ownership" v-if="isHoleAddress">{{
        $t('giveUpOwnership')
      }}</span>
    </div>
    <div class="line-wrap">
      <div class="line"></div>
    </div>
    <div
      class="risky-attention d-flex ai-center jc-between"
      v-if="isRiskyAttention"
    >
      <div
        class="warn-item d-flex ai-center jc-between"
        :class="{ dark: isDark, english: $i18n.locale === 'en' }"
      >
        <div class="d-flex ai-center">
          <img class="red" src="../assets/red.png" />
          <span class="text">{{ $t('risky') }}</span>
        </div>
        <span class="text risky-num">{{ risky }}</span>
      </div>
      <div
        class="warn-item d-flex ai-center jc-between"
        :class="{ dark: isDark, english: $i18n.locale === 'en' }"
      >
        <div class="d-flex ai-center">
          <img class="orange" src="../assets/orange.png" />
          <span class="text">{{ $t('attentionTip') }}</span>
        </div>
        <span class="text risky-num">{{ attention }}</span>
      </div>
      <div class="warn-item-pc">
        <img src="../assets/red.png" alt="" />
        <div class="risk-num">
          <p>{{ $t('risky') }}</p>
          <p>{{ risky }}</p>
        </div>
      </div>
      <div class="warn-item-pc">
        <img src="../assets/orange.png" alt="" />
        <div class="risk-num">
          <p>{{ $t('attentionTip') }}</p>
          <p>{{ attention }}</p>
        </div>
      </div>
    </div>
    <div class="securityInfo" v-if="securityList.length">
      <InfoItem
        v-for="(info, index) in securityList"
        :key="index"
        :item="info"
      />
    </div>
    <div
      class="more-security d-flex ai-center jc-between"
      :class="{ dark: isDark }"
      @click="goTokenDetection"
    >
      <span class="text">{{ $t('moreSecurityInfo') }}</span>
      <img src="../assets/arrow.png" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import InfoItem from './InfoItem.vue';

export default {
  props: {
    securityList: {
      type: Array,
    },
    attention: {
      type: Number,
    },
    risky: {
      type: Number,
    },
    isHoleAddress: {
      type: Boolean,
    },
    isRiskyAttention: {
      type: Boolean,
    },
  },
  components: { InfoItem },
  computed: {
    ...mapGetters(['isDark']),
  },
  methods: {
    goTokenDetection() {
      this.$emit('goTokenDetection');
    },
  },
};
</script>

<style lang="scss" scoped>
.security-content {
  .line-wrap {
    margin: 0 0.32rem;
  }
  .risky-attention {
    margin: 0.32rem 0.28rem 0;
    .warn-item {
      background: #f6f6f6;
      border-radius: 0.16rem;
      padding: 0.28rem 0.24rem;
      &.dark {
        background: #0d0e0f;
      }
      &.english {
        min-height: 1.5rem;
      }
      flex: 1;
      &:nth-child(2) {
        margin-left: 0.24rem;
      }
      img {
        margin-right: 0.16rem;
      }
      .red {
        width: 0.47rem;
        height: 0.45rem;
      }
      .orange {
        width: 0.45rem;
        height: 0.45rem;
      }
      span {
        font-weight: 500;
      }
      .risky-num {
        font-size: 500;
        font-size: 0.38rem;
      }
    }
    .warn-item-pc {
      display: none;
    }
  }
  .securityInfo {
    padding: 0.24rem 0.32rem 0;
  }
  .more-security {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.24rem 0.24rem 0;
    height: 1.02rem;
    // background: var(--security-bg);
    background: #2980fe;
    border-radius: 0.16rem;
    span {
      color: #fff;
    }
    img {
      margin-left: 0.08rem;
      width: 0.24rem;
      height: 0.24rem;
    }
  }
}
@media screen and (min-width: 767px) {
  .security-content {
    border-radius: 0.16rem;
    box-shadow: 0 0.04rem 0.12rem 0 rgba(0, 0, 0, 0.08);
    padding-bottom: 0.2rem;
    .line-wrap {
      display: none;
    }
    .risky-attention {
      margin: 0 0.2rem;
      .warn-item {
        display: none;
      }
      .warn-item-pc {
        width: 48%;
        padding: 0.13rem 0.2rem;
        display: flex;
        align-items: center;
        background: var(--bg-10);
        border-radius: 0.12rem;
        img {
          width: 0.36rem;
          height: 0.36rem;
        }
        .risk-num {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 0.13rem;
          p {
            font-size: 0.14rem;
            font-family: PingFang SC, PingFang SC-Regular;
            color: var(--gray-1);
            white-space: nowrap;
          }
          p:nth-child(2) {
            font-size: 0.22rem;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            color: var(--gray-1);
          }
        }
      }
    }
    .securityInfo {
      padding: 0.16rem 0.2rem 0;
    }
    .more-security {
      margin: 0.2rem 0.2rem 0;
      height: 0.49rem;
      border-radius: 0.08rem;
      cursor: pointer;
      img {
        margin-left: 0.04rem;
        width: 0.14rem;
        height: 0.14rem;
      }
    }
  }
}
@media screen and (min-width: 1400px) {
  .security-content {
    border-radius: 0.16rem;
    box-shadow: 0 0.04rem 0.12rem 0 rgba(0, 0, 0, 0.08);
    padding-bottom: 0.2rem;
    .line-wrap {
      display: none;
    }
    .risky-attention {
      margin: 0 0.2rem;
      .warn-item {
        display: none;
      }
      .warn-item-pc {
        width: 48%;
        padding: 0.13rem 0.2rem;
        display: flex;
        align-items: center;
        background: var(--bg-10);
        border-radius: 0.12rem;
        img {
          width: 0.36rem;
          height: 0.36rem;
        }
        .risk-num {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 0.13rem;
          p {
            font-size: 0.12rem;
            font-family: PingFang SC, PingFang SC-Regular;
            color: var(--gray-1);
            white-space: nowrap;
          }
          p:nth-child(2) {
            font-size: 0.2rem;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            color: var(--gray-1);
          }
        }
      }
    }
    .securityInfo {
      padding: 0.16rem 0.2rem 0;
    }
    .more-security {
      margin: 0.2rem 0.2rem 0;
      height: 0.49rem;
      border-radius: 0.08rem;
      cursor: pointer;
      img {
        margin-left: 0.04rem;
        width: 0.14rem;
        height: 0.14rem;
      }
    }
  }
}
</style>
