<template>
  <div class="swap-button" @click="goSwap">
    <span>{{ $t('trading_info.text_6') }}</span>
    <img src="../assets/arrow.png" alt="" />
  </div>
</template>

<script>
import { TRANSIT_NS_CHAIN_ID_MAP } from '../utils';
import isMobile from '../mixins/isMobile';
export default {
  props: {
    tokenDetail: {
      type: Object,
    },
  },
  mixins: [isMobile],
  computed: {
    inputChain() {
      if (this.tokenDetail && this.tokenDetail.chain_id) {
        const key = this.tokenDetail.ns + ':' + this.tokenDetail.chain_id
        if (TRANSIT_NS_CHAIN_ID_MAP[key]) {
          return TRANSIT_NS_CHAIN_ID_MAP[key];
        } else {
          return key;
        }
      } else {
        return 'ETH';
      }
    },
  },
  methods: {
    goSwap() {
      if (this.isMobile) {
        // 移动端跳转
        window.open(
          `https://swap.transit.finance/?locale=${this.$i18n.locale}&inputChain=${this.inputChain}&inputSymbol=${this.tokenDetail.symbol}&inputCurrency=${this.tokenDetail.contract}#/`,
          '_blank'
        );
      } else {
        window.open(
          `https://swap.transit.finance/?locale=${this.$i18n.locale}&inputChain=${this.inputChain}&inputSymbol=${this.tokenDetail.symbol}&inputCurrency=${this.tokenDetail.contract}#/`,
          '_blank'
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.swap-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 0;
  background: #2980fe;
  border-radius: 0.16rem;
  span {
    font-size: 0.26rem;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #ffffff;
  }
  img {
    width: 0.24rem;
    height: 0.24rem;
  }
}
@media screen and (min-width: 767px) {
  .swap-button {
    padding: 0.26rem 0;
    border-radius: 0.12rem;
    cursor: pointer;
    span {
      font-size: 0.18rem;
    }
    img {
      width: 0.2rem;
      height: 0.2rem;
    }
  }
}
@media screen and (min-width: 1400px) {
  .swap-button {
    padding: 0.26rem 0;
    border-radius: 0.12rem;
    cursor: pointer;
    span {
      font-size: 0.13rem;
    }
    img {
      width: 0.14rem;
      height: 0.14rem;
    }
  }
}
</style>
