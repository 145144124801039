<template>
  <div class="rune-container">
    <div class="rune-left" v-if="rune">
      <div class="rune-logo-name content">
        <img class="logo" v-if="rune.logo" :src="rune.logo" alt="" />
        <div class="name">{{ rune.name }}({{ rune.symbol }})</div>
      </div>
      <div class="rune-base-info content">
        <div class="rune-title">{{ $t('rune.base_title') }}</div>
        <div class="line"></div>
        <div class="message-list">
          <MessageItem
            v-for="(message, index) in baseMessageList"
            :message="message"
            :key="index"
          />
        </div>
      </div>
    </div>
    <div class="rune-right" v-if="rune">
      <div class="rune-mint-info content">
        <div class="rune-title">{{ $t('rune.mint_title') }}</div>
        <div class="line"></div>
        <div class="message-list">
          <MessageItem
            v-for="(message, index) in mintMessageList"
            :message="message"
            :key="index"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MessageItem from '../common/MessageItem.vue';
export default {
  props: {
    rune: {
      type: Object,
    },
    runeStatus: {
      type: String,
    },
    baseInfoLabel: {
      type: Object,
    },
    minDataLabel: {
      type: Object,
    },
  },
  components: { MessageItem },
  data() {
    return {
      // runeStatus: '',
    };
  },
  computed: {
    statusText() {
      if (this.runeStatus === 'pending') {
        return this.$t('rune.in_progress');
      } else if (this.runeStatus === 'not-started') {
        return this.$t('rune.not_started');
      } else if (this.runeStatus === 'over') {
        return this.$t('rune.over');
      } else {
        return '';
      }
    },
    baseMessageList() {
      return [
        {
          type: 'supply',
          label: this.$t('rune.base.text_1'),
          value: this.baseInfoLabel.supply,
        },
        {
          type: 'premine',
          label: this.$t('rune.base.text_2'),
          value: this.baseInfoLabel.premine,
        },
        {
          type: 'burned',
          label: this.$t('rune.base.text_3'),
          value: this.baseInfoLabel.burned,
        },
        {
          type: 'id',
          label: this.$t('rune.base.text_4'),
          value: this.baseInfoLabel.id,
        },
        {
          type: 'divisibility',
          label: this.$t('rune.base.text_5'),
          value: this.baseInfoLabel.divisibility,
        },
        {
          type: 'etching',
          hash: true,
          label: this.$t('rune.base.text_6'),
          value: this.baseInfoLabel.etching,
        },
      ];
    },
    mintMessageList() {
      return [
        {
          type: 'status',
          label: this.$t('rune.mint.text_1'),
          value: this.statusText,
          status: this.minDataLabel.status,
        },
        {
          type: 'etchBlock',
          label: this.$t('rune.mint.text_2'),
          value: this.minDataLabel.etchBlock,
        },
        {
          type: 'currentBlock',
          label: this.$t('rune.mint.text_3'),
          value: this.minDataLabel.currentBlock,
        },
        {
          type: 'startBlock',
          label: this.$t('rune.mint.text_4'),
          value: this.minDataLabel.startBlock,
        },
        {
          type: 'endBlock',
          label: this.$t('rune.mint.text_5'),
          value:
            this.minDataLabel.endBlock === 'Unlimited'
              ? this.$t('rune.unlimited')
              : this.minDataLabel.endBlock,
        },
        {
          type: 'perMintCount',
          label: this.$t('rune.mint.text_9'),
          value: this.minDataLabel.perMintCount,
        },
        {
          type: 'mintTotalCount',
          label: this.$t('rune.mint.text_6'),
          value:
            this.minDataLabel.mintTotalCount > 1000000000
              ? this.$t('rune.unlimited')
              : this.minDataLabel.mintTotalCount,
        },
        {
          type: 'mintedCount',
          label: this.$t('rune.mint.text_7'),
          value: this.minDataLabel.mintedCount,
        },
        {
          type: 'laveCount',
          label: this.$t('rune.mint.text_8'),
          value:
            this.minDataLabel.laveCount > 1000000000
              ? this.$t('rune.unlimited')
              : this.minDataLabel.laveCount,
        },
      ];
    },
    inProgress1() {
      return this.$t('rune.mint.text_8');
    },
  },
  mounted() {
    // this.getRune();
  },
  methods: {
    changeLang() {
      if (this.$i18n.locale === 'zh') {
        this.$i18n.locale = 'en';
      } else {
        this.$i18n.locale = 'zh';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0.2rem;
}
.rune-title {
  font-size: 0.16rem;
  font-family: PingFang SC, PingFang SC-Medium;
  color: #101010;
  color: var(--gray-15);
}
.rune-container {
  max-width: 12rem;
  margin: 0.32rem auto 0;
  display: flex;
  .line {
    margin-top: 0.2rem;
  }
  .rune-left {
    width: 50%;
    .rune-logo-name {
      display: flex;
      align-items: center;
      .logo {
        width: 0.52rem;
        height: 0.52rem;
        border-radius: 50%;
      }
      .name {
        margin-left: 0.13rem;
        font-size: 0.18rem;
        font-family: PingFang SC, PingFang SC-Medium;
        color: var(--gray-1);
        word-break: break-all;
      }
    }
    .rune-base-info {
      margin-top: 0.2rem;
      .message-list {
        margin-top: 0.2rem;
        .message-item {
          &:nth-child(n + 2) {
            margin-top: 0.16rem;
          }
        }
      }
    }
  }
  .rune-right {
    margin-left: 0.2rem;
    width: 50%;
    .rune-mint-info {
      .message-list {
        margin-top: 0.2rem;
        .message-item {
          &:nth-child(n + 2) {
            margin-top: 0.16rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .rune-container {
    padding: 0 0.3rem;
  }
}

@media screen and (max-width: 767px) {
  .rune-title {
    font-size: 0.28rem;
  }
  .rune-container {
    padding: 0;
    flex-direction: column;
    max-width: none;
    width: 100%;
    margin-top: 0;
    .line {
      margin-top: 0.28rem;
    }
    .rune-left {
      width: auto;
      margin: 0 0.3rem;
      background: var(--bg-2);
      border: 0.01rem solid var(--border-2);
      border-radius: 0.26rem;
      box-shadow: 0 0.06rem 0.3rem 0 rgba(0, 0, 0, 0.08);
      .rune-logo-name {
        width: 100%;
        border: none;
        box-shadow: none;
        margin: 0;
        padding: 0.48rem 0.32rem 0;
        border-radius: 0;
        background: none;
        .logo {
          width: 0.88rem;
          height: 0.88rem;
        }
        .name {
          margin-left: 0.22rem;
          font-size: 0.3rem;
        }
      }
      .rune-base-info {
        background: none;
        border-radius: 0;
        width: 100%;
        margin: 0;
        border: none;
        box-shadow: none;
        padding: 0.48rem 0.32rem 0.32rem;
        .message-list {
          margin-top: 0.28rem;
          .message-item {
            &:nth-child(n + 2) {
              margin-top: 0.28rem;
            }
          }
        }
      }
    }
    .rune-right {
      margin-left: 0;
      margin-top: 0.32rem;
      width: 100%;
      .rune-mint-info {
        border-radius: 0.26rem;
        padding: 0.32rem;
        .message-list {
          margin-top: 0.28rem;
          .message-item {
            &:nth-child(n + 2) {
              margin-top: 0.28rem;
            }
          }
        }
      }
    }
  }
}
</style>
