<template>
  <div class="infoItem d-flex ai-center">
    <img
      class="status-img"
      :src="imgStatus"
      :class="{ red: item.status == 1 }"
    />
    <span class="text">{{ item.label }}</span>
    <div class="doubt-wrap" v-if="item.is_honeypot">
      <img
        class="doubt-img"
        :src="doubtImg"
        @click="
          $emit('getToastTitle', { title: $t('is_honeypot'), short: false })
        "
      />
      <div class="tip-text">
        {{ $t('is_honeypot') }}
        <div class="triangle"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(['isDark']),
    doubtImg() {
      let str = '';
      if (this.isDark) {
        str = '-dark';
      }
      return require(`../assets/doubt${str}.png`);
    },
    imgStatus() {
      if (this.item?.status == 0) {
        return require('../assets/green.png');
      } else if (this.item?.status == 1) {
        return require('../assets/orange.png');
      } else {
        return require('../assets/red.png');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.infoItem {
  &:nth-child(n + 2) {
    margin-top: 0.24rem;
  }
  .status-img {
    width: 0.36rem;
    height: 0.36rem;
  }
  .doubt-wrap {
    position: relative;
    display: inherit;
    .doubt-img {
      margin-left: 0.1rem;
      width: 0.18rem;
      height: 0.18rem;
      cursor: pointer;
    }
    .tip-text {
      display: none;
    }
  }
  .doubt-wrap:hover {
    .tip-text {
      display: block;
    }
  }
  .red {
    width: 0.38rem;
    height: 0.36rem;
  }
  span {
    margin-left: 0.16rem;
  }
}
@media screen and (max-width: 767px) {
  .infoItem {
    .doubt-wrap:hover {
      .tip-text {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 767px) {
  .infoItem {
     &:nth-child(n + 2) {
      margin-top: 0.16rem;
    }
    .status-img {
      width: 0.16rem;
      height: 0.16rem;
    }
    .doubt-wrap {
      .doubt-img {
        pointer-events: none;
      }
    }
    .red {
      width: 0.16rem;
      height: 0.16rem;
    }
    span {
      margin-left: 0.08rem;
      font-size: 0.15rem;
      font-family: PingFang SC, PingFang SC-Regular;
    }
  }
}
@media screen and (min-width: 1400px) {
  .infoItem {
     &:nth-child(n + 2) {
      margin-top: 0.16rem;
    }
    .doubt-wrap {
      .doubt-img {
        pointer-events: none;
      }
    }
    .red {
      width: 0.14rem;
      height: 0.14rem;
    }
    span {
      margin-left: 0.08rem;
      font-size: 0.13rem;
      font-family: PingFang SC, PingFang SC-Regular;
    }
  }
}
</style>
