<template>
  <div class="token-info">
    <div class="token-logo-wrap">
      <img class="token-logo" :src="tokenDetail.icon_url" alt="" />
    </div>
    <div class="token-info-right">
      <div class="token-info-detail">
        <div class="symbol">{{ tokenDetail.symbol }}</div>
        <div class="name">({{ isBRC20 ? 'brc20' : tokenDetail.name }})</div>
        <div class="token-risk" v-if="isRisk">{{ $t('risky_2') }}</div>
      </div>
      <div class="token-info-price">
        <div class="price" v-if="tokenDetail.token_status !== 4">
          ${{ tokenDetail.price_usd }}
        </div>
        <div class="price" v-else>-</div>
        <div
          class="chg"
          :class="{ red: tokenDetail.chg < 0, gray: tokenDetail.chg == 0 }"
        >
          {{
            tokenDetail.token_status !== 4
              ? tokenDetail.chg >= 0
                ? tokenDetail.chg == 0
                  ? '0'
                  : '+' + tokenDetail.chg
                : tokenDetail.chg
              : '0'
          }}%
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tokenDetail: {
      type: Object,
    },
    isBRC20: {
      type: Boolean
    }
  },
  computed: {
    isRisk() {
      if (this.tokenDetail) {
        if (
          this.tokenDetail.token_status === 2 ||
          this.tokenDetail.token_status === 4
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.token-info {
  background: var(--bg-2);
  // border: 0.01rem solid var(--border-2);
  border-radius: 0.16rem 0.16rem 0 0;
  // box-shadow: 0 00.04rem 0.12rem 0 rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  padding: 0.18rem;
  .token-logo-wrap {
    display: flex;
    .token-logo {
      width: 0.54rem;
      height: 0.54rem;
      border-radius: 50%;
    }
  }
  .token-info-right {
    margin-left: 0.12rem;
    .token-info-detail {
      display: flex;
      align-items: center;
      .symbol {
        font-size: 0.18rem;
        font-family: PingFang SC, PingFang SC-Medium;
        color: var(--gray-1);
      }
      .name {
        margin-left: 0.05rem;
        font-size: 0.18rem;
        font-family: PingFang SC, PingFang SC-Medium;
        color: var(--gray-11);
        min-width: auto;
        line-height: normal;
      }
      .token-risk {
        margin-left: 0.05rem;
        border: 0.01rem solid #e55036;
        border-radius: 0.06rem;
        font-size: 0.12rem;
        font-family: PingFang SC, PingFang SC-Medium;
        color: #e55036;
        padding: 0.02rem 0.1rem;
      }
    }
    .token-info-price {
      margin-top: 0.05rem;
      display: flex;
      align-items: center;
      .price {
        font-size: 0.15rem;
        font-family: PingFang SC, PingFang SC-Medium;
        color: #03c288;
      }
      .chg {
        margin-left: 0.08rem;
        background: #03c288;
        border-radius: 0.04rem;
        font-size: 0.12rem;
        font-family: PingFang SC, PingFang SC-Medium;
        color: #ffffff;
        transform: scale(0.92);
        padding: 0.02rem 0.06rem;
        &.red {
          background: #e55036;
        }
        &.gray {
          background: #c1c1c1;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .token-info {
    padding: 0.32rem;
    .token-logo-wrap {
      .token-logo {
        width: 0.88rem;
        height: 0.88rem;
      }
    }
    .token-info-right {
      margin-left: 0.24rem;
      .token-info-detail {
        .symbol {
          font-size: 0.28rem;
        }
        .name {
          font-size: 0.22rem;
        }
        .token-risk {
          margin-left: 0.12rem;
          font-size: 0.18rem;
        }
      }
      .token-info-price {
        .price {
          font-size: 0.28rem;
        }
        .chg {
          font-size: 0.2rem;
        }
      }
    }
  }
}
</style>
