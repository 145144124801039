<template>
  <div class="tabs" :class="{ 'holders-tab': holders }">
    <div
      class="tab"
      :class="{ active: tabType === tab.type, 'holders-tab': holders }"
      v-for="(tab, index) in tabList"
      :key="index"
      @click="onTab(tab.type)"
    >
      <div class="text-wrap">
        {{ tab.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabList: {
      type: Array,
    },
    curType: {
      type: String,
    },
    holders: {
      type: Boolean,
    },
  },
  data() {
    return {
      tabType: 0,
    };
  },
  mounted() {
    if (this.curType) {
      this.tabType = this.curType;
    }
  },
  methods: {
    onTab(type) {
      this.tabType = type;
      this.$emit('getTab', type);
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  // margin: 0 0.32rem;
  border-radius: 0.16rem;
  display: flex;
  background: var(--bg-2);
  border: 0.01rem solid var(--border-2);
  box-shadow: 0 0.006rem 0.3rem 0 rgba(0, 0, 0, 0.08);
  width: fit-content;
  &.holders-tab {
    background: var(--bg-10);
    padding: 0.04rem;
  }
  .tab {
    padding: 0.14rem 0.32rem;
    font-size: 0.28rem;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    border-radius: 0.16rem;
    color: var(--gray-1);
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    .text-wrap {
    }
    &.holders-tab {
      padding: 0.05rem 0.2rem;
      font-size: 0.22rem;
    }
    &.active {
      background: #2980fe;
      color: #fff;
    }
  }
}
@media screen and (min-width: 767px) {
  .tabs {
    border-radius: 0.08rem;
    justify-content: space-between;
    &.holders-tab {
      padding: 0.04rem;
    }
    .tab {
      font-size: 0.14rem;
      border-radius: 0.06rem;
      &.holders-tab {
        padding: 0.03rem 0.12rem;
        font-size: 0.14rem;
      }
      &.active {
        background: #2980fe;
        color: #fff;
      }
    }
  }
}
@media screen and (min-width: 1400px) {
  .tabs {
    border-radius: 0.08rem;
    justify-content: space-between;
    &.holders-tab {
      padding: 0.04rem;
    }
    .tab {
      font-size: 0.12rem;
      border-radius: 0.06rem;
      &.holders-tab {
        padding: 0.03rem 0.12rem;
        font-size: 0.12rem;
      }
      &.active {
        background: #2980fe;
        color: #fff;
      }
    }
  }
}
</style>
