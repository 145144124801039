<template>
  <div
    class="skeleton-circle-block"
    :style="{
      width: width ? width : '0.88rem',
      height: height ? height : '0.88rem',
      'border-radius': radius ? radius : '50%',
    }"
  ></div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    radius: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.skeleton-circle-block {
  background: var(--skeleton-bg);
}
</style>
