<template>
  <div class="root-data">
    <div class="team-content">
      <div class="team-header">
        <div class="team-title">{{ $t('root_data.tab_3') }}</div>
      </div>
      <div class="line"></div>
      <div class="team-list" v-if="teamList.length">
        <div class="team-item" v-for="(item, index) in teamList" :key="index">
          <div class="team-left">
            <div class="team-avatar">
              <img v-if="item.head_img" :src="item.head_img" alt="" />
              <img v-else src="../assets/root-data/avatar.png" alt="" />
            </div>
            <div class="team-message">
              <div class="team-name text-ellipsis-1">{{ item.name }}</div>
              <div class="team-position text-ellipsis-1">
                {{ item.position }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="no-data" v-else>
        <img v-if="loading" class="loading" :src="loadingImg" alt="" />
        <template v-else>
          <img class="no-data-img" :src="noDataImg" alt="" />
          <span>{{ $t('root_data.noData') }}</span>
        </template>
      </div>
      <div
        class="checkout-all"
        v-if="teamList.length !== originTeamList.length"
        @click="showAll('team')"
      >
        <div class="checkout-text">{{ $t('root_data.checkAll') }}</div>
        <img src="../assets/root-data/down-blue.png" alt="" />
      </div>
    </div>
    <div class="investors-content">
      <div class="investors-header">
        <div class="investors-title">{{ $t('root_data.investors') }}</div>
        <div class="investors-amount" v-if="total_funding">
          {{ $t('root_data.financingAmount') }}: ${{ total_funding.toLocaleString() }}
        </div>
      </div>
      <div class="line"></div>
      <div class="investors-list" v-if="investorsList.length">
        <div
          class="investors-item"
          v-for="(investors, index) in investorsList"
          :key="index"
        >
          <div class="investors-logo">
            <img :src="investors.logo" alt="" />
          </div>
          <div class="investors-info">
            <div class="investors-name text-ellipsis-2">
              {{ investors.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="no-data" v-else>
        <img v-if="loading" class="loading" :src="loadingImg" alt="" />
        <template v-else>
          <img class="no-data-img" :src="noDataImg" alt="" />
          <span>{{ $t('root_data.noData') }}</span>
        </template>
      </div>
      <div
        class="checkout-all"
        v-if="investorsList.length !== originInvestorsList.length"
        @click="showAll('investors')"
      >
        <div class="checkout-text">{{ $t('root_data.checkAll') }}</div>
        <img src="../assets/root-data/down-blue.png" alt="" />
      </div>
    </div>
    <div class="root_data-power">
      <RootDataImg />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RootDataImg from '../components/RootDataImg.vue';
export default {
  props: {
    contract_address: {
      type: String,
    },
    teamList: {
      type: Array,
    },
    originTeamList: {
      type: Array,
    },
    investorsList: {
      type: Array,
    },
    originInvestorsList: {
      type: Array,
    },
    total_funding: {
      type: [String, Number],
    },
    loading: {
      type: Boolean,
    },
  },
  components: { RootDataImg },
  computed: {
    ...mapGetters(['isMobileSize', 'isDark']),
    noDataImg() {
      let str = '';
      if (this.isDark) {
        str = '-dark';
      }
      return require(`../assets/root-data/no-data${str}.png`);
    },
    loadingImg() {
      let str = '';
      if (this.isDark) {
        str = '-dark';
      }
      return require(`../assets/loading${str}.png`);
    },
  },
  created() {},
  methods: {
    showAll(type) {
      if (type === 'team') {
        this.$emit('showAll', 'team');
      } else {
        this.$emit('showAll', 'investors');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.root-data {
  margin-top: 0.24rem;
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .checkout-all {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 0.16rem auto 0;
    cursor: pointer;
    .checkout-text {
      font-size: 0.12rem;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #2980fe;
    }
    img {
      margin-left: 0.04rem;
      width: 0.12rem;
      height: 0.13rem;
    }
  }
  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 0;
    .loading {
      width: 0.6rem;
      height: 0.6rem;
      animation: rotate 2s linear infinite;
    }
    .no-data-img {
      display: none;
    }
    span {
      font-size: 0.12rem;
      font-family: PingFang SC, PingFang SC-Regular;
      color: var(--gray-14);
    }
  }
  .line {
    display: none;
  }
  .team-content {
    border: 0.01rem solid var(--border-3);
    border-radius: 0.12rem;
    padding: 0.16rem;
    .team-header {
      .team-title {
        font-size: 0.16rem;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: var(--gray-15);
      }
    }
    .team-list {
      display: grid;
      justify-content: space-between;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 0.15rem 0.12rem;
      margin-top: 0.16rem;
      .team-item {
        display: flex;
        align-items: center;
        background: var(--bg-10);
        border-radius: 0.12rem;
        padding: 0.16rem;
        .team-left {
          display: flex;
          align-items: center;
          .team-avatar {
            display: flex;
            img {
              width: 0.36rem;
              height: 0.36rem;
              border-radius: 50%;
            }
          }
          .team-message {
            margin-left: 0.08rem;
            .team-name {
              font-size: 0.12rem;
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 500;
              color: var(--gray-1);
            }
            .team-position {
              font-size: 0.12rem;
              font-family: PingFang SC, PingFang SC-Regular;
              color: var(--gray-11);
              transform: scale(0.9);
              margin-left: -0.03rem;
            }
          }
        }
      }
    }
  }
  .investors-content {
    margin-top: 0.12rem;
    border: 0.01rem solid var(--border-3);
    border-radius: 0.12rem;
    padding: 0.16rem;
    .investors-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .investors-title {
        font-size: 0.16rem;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: var(--gray-15);
      }
      .investors-amount {
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC-Regular;
        color: var(--gray-1);
      }
    }
    .investors-list {
      display: grid;
      justify-content: space-between;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 0.15rem 0.1rem;
      margin-top: 0.16rem;
      .investors-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 0.1rem;
        // width: 12%;
        .investors-logo {
          display: flex;
          img {
            width: 0.36rem;
            height: 0.36rem;
            border-radius: 50%;
            border: 0.01rem solid var(--border-4);
          }
        }
        .investors-info {
          margin-top: 0.08rem;
          .investors-name {
            font-size: 0.12rem;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            text-align: center;
            color: var(--gray-1);
          }
        }
      }
    }
  }
  .root_data-power {
    display: none;
  }
}
@media screen and(max-width: 767px) {
  .root-data {
    width: calc(100% - 0.64rem);
    margin: 0.24rem auto 0;
    background: var(--bg-2);
    border: 0.01rem solid var(--border-2);
    border-radius: 0.2rem;
    box-shadow: 0 0.06rem 0.3rem 0 rgba(0, 0, 0, 0.08);
    .checkout-all {
      margin-top: 0.2rem;
      .checkout-text {
        font-size: 0.24rem;
      }
      img {
        width: 0.24rem;
        height: 0.24rem;
      }
    }
    .no-data {
      flex-direction: column;
      .no-data-img {
        display: block;
        width: 0.72rem;
        height: 0.72rem;
      }
      span {
        margin-top: 0.12rem;
        font-size: 0.22rem;
      }
    }
    .team-content {
      border: none;
      padding: 0.28rem 0.32rem 0;
      .team-header {
        .team-title {
          font-size: 0.28rem;
        }
      }
      .line {
        display: block;
        margin-top: 0.28rem;
      }
      .team-list {
        margin-top: 0.32rem;
        grid-template-columns: 1fr 1fr;
        .team-item {
          .team-left {
            .team-avatar {
              img {
                width: 0.72rem;
                height: 0.72rem;
              }
            }
            .team-message {
              margin-left: 0.16rem;
              .team-name {
                font-size: 0.22rem;
              }
              .team-position {
                font-size: 0.18rem;
              }
            }
          }
        }
      }
    }
    .investors-content {
      border: none;
      padding: 0 0.32rem 0.28rem;
      margin-top: 0.52rem;
      .investors-header {
        flex-direction: column;
        align-items: flex-start;
        .investors-title {
          font-size: 0.28rem;
        }
        .investors-amount {
          margin-top: 0.08rem;
          font-size: 0.22rem;
        }
      }
      .line {
        display: block;
        margin-top: 0.28rem;
      }
      .investors-list {
        margin-top: 0.4rem;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 0.32rem 0.1rem;
        .investors-item {
          .investors-logo {
            img {
              width: 0.72rem;
              height: 0.72rem;
            }
          }
          .investors-info {
            .investors-name {
              font-size: 0.2rem;
              -webkit-line-clamp: 1;
            }
          }
        }
      }
    }
    .root_data-power {
      display: flex;
      width: fit-content;
      margin: 0.1rem auto 0.36rem;
      img {
        height: 0.2rem;
      }
    }
  }
}
</style>
