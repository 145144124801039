<template>
  <div class="mobile-tabs">
    <div
      class="mobile-tab"
      :class="{ active: tabType === tab.type }"
      v-for="(tab, index) in tabList"
      :key="index"
      @click="onTab(tab.type)"
    >
      <img v-if="tabType === tab.type" :src="tab.activeIcon" alt="" />
      <img v-else :src="tab.icon" alt="" />
      <div class="tab-title" :class="{ 'chinese': $i18n.locale === 'zh' }">
        <span>{{ tab.title }}</span>
      </div>
      <div class="bottom-line" :class="{ active: tabType === tab.type }"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabList: {
      type: Array,
    },
    curType: {
      type: String,
    },
    holders: {
      type: Boolean,
    },
  },
  data() {
    return {
      tabType: 0,
    };
  },
  mounted() {
    if (this.curType) {
      this.tabType = this.curType;
    }
  },
  methods: {
    onTab(type) {
      this.tabType = type;
      this.$emit('getTab', type);
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-tabs {
  display: flex;
  align-items: center;
  .mobile-tab {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.active {
      .tab-title {
        span {
          color: #2980fe;
          font-weight: 500;
        }
      }
      .bottom-line {
        background: #2980fe;
      }
    }
    img {
      margin-top: 0.4rem;
      width: 0.48rem;
      height: 0.48rem;
    }
    .tab-title {
      display: flex;
      align-items: center;
      height: 0.6rem;
      margin-top: 0.05rem;
      &.chinese {
        height: 0.4rem;
        line-height: 0.4rem;
        span {
          width: auto;
        }
      }
      span {
        display: block;
        width: 0.9rem;
        font-size: 0.22rem;
        text-align: center;
        color: var(--gray-17);
        word-break: break-word;
      }
    }
    .bottom-line {
      margin-top: 0.16rem;
      width: 100%;
      height: 0.04rem;
      background: none;
    }
  }
}
@media screen and (min-width: 767px) {
}
@media screen and (min-width: 1400px) {
}
</style>
