<template>
  <div class="top-100-item">
    <div class="order">
      <span :class="{ active: top100Item.rank < 4 }">{{ top100Item.rank }}.</span>
    </div>
    <div class="value">{{ top100Item.amount }}</div>
    <div class="value percent">{{ top100Item.percent }}%</div>
    <a :href="accountUrl" target="_blank" class="address">
      <span>{{
        top100Item.holderAddress.slice(0, 3) +
        '...' +
        top100Item.holderAddress.slice(-4)
      }}</span>
      <img v-if="isDark" src="../assets/share-dark.png" alt="" />
      <img v-else src="../assets/share.png" alt="" />
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    top100Item: {
      type: Object,
    },
    blockchain_id: {
      type: Number,
    },
    account_url: {
      type: String,
    },
    isBRC20: {
      type: Boolean,
    },
    contract: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(['isDark']),
    accountUrl() {
      if (this.isBRC20) {
        // 922961688
        return `https://hk.unisat.io/brc20?q=${this.top100Item.holderAddress}&tick=${this.contract}`
      } else {
        return this.account_url.replace('{account}', this.top100Item.holderAddress)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.top-100-item {
  display: flex;
  align-items: center;
  .order {
    flex: 0.5;
    span {
      display: inline-block;
      font-size: 0.24rem;
      font-family: PingFang SC, PingFang SC-Regular;
      color: var(--gray-11);
      &.active {
        width: 0.32rem;
        height: 0.32rem;
        background: #2980fe;
        border-radius: 0.08rem;
        color: #fff;
        text-align: center;
        line-height: 0.32rem;
      }
    }
  }
  .value {
    flex: 1.3;
    font-size: 0.26rem;
    font-family: PingFang SC, PingFang SC-Regular;
    color: var(--gray-1);
  }
  .percent {
    flex: 0.7;
  }
  .address {
    flex: 1.5;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span {
      font-size: 0.24rem;
      font-family: PingFang SC, PingFang SC-Regular;
      color: var(--gray-11);
    }
    img {
      margin-left: 0.08rem;
      width: 0.28rem;
      height: 0.28rem;
    }
  }
}
@media screen and(min-width: 767px) {
  .top-100-item {
    .order {
      span {
        font-size: 0.12rem;
        &.active {
          width: 0.18rem;
          height: 0.18rem;
          border-radius: 0.04rem;
          line-height: 0.18rem;
        }
      }
    }
    .value {
      font-size: 0.17rem;
    }
    .percent {
      // font-size: 13px;
    }
    .address {
      span {
        font-size: 0.16rem;
      }
      img {
        margin-left: 0.05rem;
        width: 0.16rem;
        height: 0.16rem;
      }
    }
  }
}
@media screen and(min-width: 1400px) {
  .top-100-item {
    .order {
      span {
        font-size: 0.12rem;
        &.active {
          width: 0.18rem;
          height: 0.18rem;
          border-radius: 0.04rem;
          line-height: 0.18rem;
        }
      }
    }
    .value {
      font-size: 0.15rem;
    }
    .percent {
      // font-size: 13px;
    }
    .address {
      span {
        font-size: 0.14rem;
      }
      img {
        margin-left: 0.05rem;
        width: 0.16rem;
        height: 0.16rem;
      }
    }
  }
}
</style>
