<template>
  <div class="no-root-data">
    <div class="no-root-data-title">{{ title }}</div>
    <div class="line"></div>
    <div class="no-root-data-wrap" :class="{ trading: noDataType === 'trading' }">
      <img v-if="isDark" src="../assets/root-data/no-data-dark.png" alt="" />
      <img v-else src="../assets/root-data/no-data.png" alt="" />
      <span>{{ noData }}</span>
    </div>
    <div class="root_data-power" v-if="noDataType === 'root-data'">
      <RootDataImg />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RootDataImg from '../components/RootDataImg.vue';
export default {
  props: {
    noDataType: {
      type: String,
      default: 'root-data'
    },
  },
  components: { RootDataImg },
  computed: {
    ...mapGetters(['isDark']),
    title() {
      if (this.noDataType === 'root-data') {
        return this.$t('root_data.tab_3')
      } else {
        return this.$t('trading_info.title_3')
      }
    },
    noData() {
      if (this.noDataType === 'root-data') {
        return this.$t('root_data.noData_1')
      } else {
        return this.$t('trading_info.noData_1')
      }
    }
  },
};
</script>

<style lang="scss">
.no-root-data {
  margin-top: 0.32rem;
  height: 3.62rem;
  border: 0.01rem solid var(--border-3);
  border-radius: 0.12rem;
  padding: 0.16rem;
  .no-root-data-title {
    font-size: 0.16rem;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: var(--gray-15);
  }
  .line {
    display: none;
  }
  .no-root-data-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0.9rem;
    img {
      width: 0.56rem;
      height: 0.56rem;
    }
    span {
      margin-top: 0.08rem;
      font-size: 0.12rem;
      font-family: PingFang SC, PingFang SC-Regular;
      color: var(--gray-16);
      transform: scale(0.9);
    }
  }
  .root_data-power {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .no-root-data {
    // width: calc(100% - 0.64rem);
    // height: auto;
    // margin: 0.2rem auto 0;
    // padding: 0.28rem 0.32rem 0.36rem;
    // border: 0.01rem dashed var(--border-2);
    // border-radius: 0.2rem;
    // box-shadow: 0 0.06rem 0.3rem 0 rgba(0, 0, 0, 0.08);
    height: auto;
    border: none;
    border-radius: 0;
    padding: 0 0.32rem;
    .no-root-data-title {
      font-size: 0.28rem;
      line-height: 0.54rem;
    }
    .line {
      display: block;
      margin-top: 0.28rem;
    }
    .no-root-data-wrap {
      margin-top: 1.52rem;
      &.trading {
        margin: 1.52rem 0;
      }
      img {
        width: 0.88rem;
        height: 0.88rem;
      }
      span {
        margin-top: 0.12rem;
        font-size: 0.22rem;
      }
    }
    .root_data-power {
      display: flex;
      width: fit-content;
      margin: 2rem auto 0;
      img {
        height: 0.2rem;
      }
    }
  }
}
</style>
