<template>
  <div class="root_data-img" @click="goRootData">
    <img v-if="$i18n.locale === 'zh'" :src="rootDataZhImg" alt="" />
    <img v-else :src="rootDataEnImg" alt="" />
  </div>
</template>

<script>
import darkModeImg from '../mixins/darkModeImg';
export default {
  mixins: [darkModeImg],
  computed: {
    rootDataZhImg() {
      return require(`../assets/root-data/root-data-zh${this.darkModeImg}.png`);
    },
    rootDataEnImg() {
      return require(`../assets/root-data/root-data-en${this.darkModeImg}.png`);
    },
  },
  methods: {
    goRootData() {
      window.open(
        'https://www.rootdata.com/zh?utm_source=TP&utm_medium=wallet',
        'blank'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.root_data-img {
  display: flex;
  cursor: pointer;
  img {
    height: 0.12rem;
  }
}
@media screen and (max-width: 767px) {
  .root_data-img {
    img {
      height: 0.2rem;
    }
  }
}
</style>
