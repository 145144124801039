<template>
  <div
    class="toast d-flex flex-column ai-center"
    :class="{
      noCopyContract: !isCopyContract || isBRC20,
      'toast-title': toastTitleObj.title,
      short: toastTitleObj.short,
      dark: isDark,
    }"
  >
    <template v-if="toastTitleObj.title">
      <span class="text title">
        {{ toastTitleObj.title }}
      </span>
    </template>
    <template v-else>
      <div
        class="toast-content"
        v-if="isCopyContract && !isBRC20"
      >
        <img src="../assets/orange.png" />
        <span class="text">{{ $t('copySuccess') }}</span>
      </div>
      <div class="toast-content d-flex ai-center jc-center" v-else>
        <img src="../assets/success.png" />
        <span class="text">{{ $t('copySuccess2') }}</span>
      </div>
      <div v-if="isCopyContract && !isBRC20" class="text button" @click="close">
        {{ $t('understood') }}
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    isCopyContract: {
      type: Boolean,
    },
    toastTitleObj: {
      type: Object,
    },
    isBRC20: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(['isDark']),
    copyContractText() {
      if (this.isBRC20) {
        return this.t('copySuccess')
      } else {
        return this.t('copySuccess')
      }
    }
  },
  methods: {
    close() {
      this.$emit('closeToast')
    },
  },
}
</script>

<style lang="scss" scoped>
.toast {
  position: fixed;
  z-index: 9998;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  padding: 0.32rem;
  background: #fff;
  border: 0.01rem solid #eaeff3;
  border-radius: 0.20rem;
  box-shadow: 0 0.32rem 0.64rem 0 rgba(192, 192, 192, 0.4);
  &.noCopyContract {
    width: 60%;
    padding: 0.2rem 0.3rem;
  }
  &.brc20 {
    width: 60%;
    padding: 0.2rem 0.3rem;
  }
  &.dark {
    background: #000;
    box-shadow: none;
    border: 0.01rem solid #000;
  }
  &.toast-title {
    width: auto;
    min-width: 80%;
    padding: 0.34rem 0.44rem;
    border-radius: 0.24rem;
    background: #000;
    border: none;
    &.short {
      width: 45%;
      min-width: auto;
    }
    &.dark {
      background: #556489;
    }
  }
  .title {
    padding: 0;
    font-size: 0.16rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    &.dark {
      color: #d7dae0;
    }
  }
  .toast-content {
    display: flex;
    img {
      width: 0.4rem;
      height: 0.4rem;
    }
    span {
      margin-left: 0.2rem;
      font-size: 0.28rem;
      font-weight: 500;
      // color: #3a3b3d;
      color: #f56459;
      line-height: 0.4rem;
    }
  }
  .button {
    display: inline-block;
    margin-top: 0.2rem;
    padding: 0.1rem 0.3rem;
    background: #2980fe;
    border-radius: 0.12rem;
    text-align: center;
    color: #fff;
    cursor: pointer;
    font-size: 0.24rem;
  }
}
@media screen and (min-width: 992px) {
  .toast {
    width: auto;
    min-width: auto;
    padding: 0.2rem;
    border-radius: 0.16rem;
    &.noCopyContract {
      width: auto;
    }
    &.toast-title {
      min-width: auto;
    }
    .toast-content {
      img {
        width: 0.28rem;
        height: 0.28rem;
      }
      span {
        font-size: 0.16rem;
        line-height: 0.22rem;
      }
    }
    .button {
      font-size: 0.14rem;
    }
  }
}
</style>
