<template>
  <div class="loading">
    <img :src="loadingImg" alt="" :style="{ width, height }" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    width: {
      type: String,
      default: '0.48rem'
    },
    height: {
      type: String,
      default: '0.48rem'
    },
  },
  computed: {
    ...mapGetters(['isDark']),
    loadingImg() {
      let str = '';
      if (this.isDark) {
        str = '-dark';
      }
      return require(`../assets/loading${str}.png`);
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  img {
    width: 0.6rem;
    height: 0.6rem;
    animation: rotate 2s linear infinite;
  }
}
@media screen and (min-width: 767px) {
  .loading {
    height: 60vh;
    img {
      width: 0.48rem;
      height: 0.48rem;
    }
  }
}
</style>
