<template>
  <div class="skeleton-block" :style="{ width: width ? width: '100%', height: height ? height : '0.52rem' }"></div>
</template>

<script>
export default {
  props: {
    width: {
      type: String
    },
    height: {
      type: String
    },
  }
};
</script>

<style lang="scss" scoped>
.skeleton-block {
  height: 0.52rem;
  background: var(--skeleton-bg);
  border-radius: 0.08rem;
}
</style>
