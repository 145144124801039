<template>
  <div class="top-item">
    <div class="top-item-left">
      <div class="top-color" :class="[topObj.color]"></div>
      <div class="top-title">{{ topObj.title }}</div>
    </div>
    <div class="top-item-right">
      <div class="amount">{{ topObj.value }}</div>
      <div class="symbol">
        <span>{{ topObj.symbol }}</span>
      </div>
      <div class="percent">({{ topObj.percent }}%)</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    topObj: {
      type: Object,
      default: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.top-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .top-item-left {
    display: flex;
    align-items: center;
    .top-color {
      width: 0.16rem;
      height: 0.16rem;
      background: #f5454d;
      border-radius: 0.08rem;
      &.yellow {
        background: #fcb244;
      }
      &.green {
        background: #e7f06e;
      }
      &.blue {
        background: #2980fe;
      }
    }
    .top-title {
      margin-left: 0.12rem;
      font-size: 0.26rem;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: var(--gray-1);
    }
  }
  .top-item-right {
    display: flex;
    align-items: center;
    .amount {
      font-size: 0.26rem;
      font-family: PingFang SC, PingFang SC-Regular;
      color: var(--gray-1);
    }
    .symbol {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0.2rem 0 0.08rem;
      padding: 0 0.05rem;
      background: var(--bg-11);
      border-radius: 0.08rem;
      span {
        font-size: 0.26rem;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #ffffff;
        transform: scale(0.85);
      }
    }
    .percent {
      width: 1.2rem;
      font-size: 0.26rem;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: var(--gray-11);
      text-align: right;
    }
  }
}
@media screen and (min-width: 767px) {
  .top-item {
    .top-item-left {
      .top-color {
        width: 0.12rem;
        height: 0.12rem;
        background: #f5454d;
        border-radius: 0.03rem;
      }
      .top-title {
        font-size: 0.16rem;
      }
    }
    .top-item-right {
      .amount {
        font-size: 0.16rem;
      }
      .symbol {
        margin: 0 0.04rem 0;
        border-radius: 0.04rem;
        padding: 0.01rem 0.04rem;
        span {
          font-size: 0.14rem;
          color: #ffffff;
        }
      }
      .percent {
        width: 0.65rem;
        font-size: 0.16rem;
      }
    }
  }
}
@media screen and (min-width: 1400px) {
  .top-item {
    .top-item-left {
      .top-color {
        width: 0.12rem;
        height: 0.12rem;
        background: #f5454d;
        border-radius: 0.03rem;
      }
      .top-title {
        font-size: 0.14rem;
      }
    }
    .top-item-right {
      .amount {
        font-size: 0.14rem;
      }
      .symbol {
        margin: 0 0.04rem 0;
        border-radius: 0.04rem;
        padding: 0.01rem 0.04rem;
        span {
          font-size: 0.12rem;
          color: #ffffff;
        }
      }
      .percent {
        width: 0.65rem;
        font-size: 0.14rem;
      }
    }
  }
}
</style>
