<template>
  <div class="pool-item">
    <div class="dex">
      <img :src="poolItem.icon" alt="" />
      <div class="pool-name">{{ poolItem.name }}</div>
      <div class="largest" v-if="poolItem.isLargest">
        <span>{{ $t('trading_info.text_10') }}</span>
      </div>
    </div>
    <div class="pair">
      <div class="symbol0">{{ poolItem.symbol0 }}/</div>
      <div class="symbol1">{{ poolItem.symbol1 }}</div>
    </div>
    <div class="liquidity">{{ poolItem.market_value }}</div>
  </div>
</template>

<script>
export default {
  props: {
    poolItem: {
      type: Object,
      default: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.pool-item {
  display: flex;
  align-items: center;
  .dex {
    flex: 1;
    display: flex;
    align-items: center;
    img {
      width: 0.6rem;
      height: 0.6rem;
    }
    .pool-name {
      display: none;
      font-size: 0.15rem;
      font-family: PingFang SC, PingFang SC-Regular;
      color: var(--gray-1);
    }
    .largest {
      margin-left: 0.12rem;
      padding: 0.04rem 0.1rem;
      background: #2980fe;
      border-radius: 0.06rem;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 0.16rem;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
  .pair {
    flex: 1;
    display: flex;
    align-items: center;
    div {
      font-size: 0.26rem;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: var(--gray-1);
    }
    .symbol1 {
      color: var(--gray-11);
    }
  }
  .liquidity {
    flex: 1;
    text-align: right;
    font-size: 0.26rem;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: var(--gray-1);
  }
}
@media screen and (min-width: 767px) {
  .pool-item {
    .dex {
      img {
        width: 0.32rem;
        height: 0.32rem;
      }
      .pool-name {
        display: block;
        font-size: 0.17rem;
        margin-left: 0.08rem;
      }
      .largest {
        margin-left: 0.08rem;
        padding: 0.02rem 0.06rem;
        span {
          font-size: 0.14rem;
          transform: scale(0.85);
        }
      }
    }
    .pair {
      div {
        font-size: 0.17rem;
      }
    }
    .liquidity {
      font-size: 0.17rem;
    }
  }
}
@media screen and (min-width: 1400px) {
  .pool-item {
    .dex {
      img {
        width: 0.32rem;
        height: 0.32rem;
      }
      .pool-name {
        display: block;
        font-size: 0.15rem;
        margin-left: 0.08rem;
      }
      .largest {
        margin-left: 0.08rem;
        padding: 0.02rem 0.06rem;
        span {
          font-size: 0.12rem;
          transform: scale(0.85);
        }
      }
    }
    .pair {
      div {
        font-size: 0.15rem;
      }
    }
    .liquidity {
      font-size: 0.15rem;
    }
  }
}
</style>
