<template>
  <div class="message-item">
    <div class="message-item-left">
      <div class="label">{{ message.label }}</div>
    </div>
    <div class="message-item-right">
      <div
        class="value"
        :class="{
          status: message.type === 'status' && message.value,
          'status-not-started': message.status === 'not-started',
          'status-pending': message.status === 'pending',
          'status-over': message.status === 'over',
          hash: message.hash,
        }"
        @click="link(message)"
      >
        <span v-if="message.value && message.type === 'etching'">
          {{ message.value.slice(0, 8) + '...' + message.value.slice(-8) }}
        </span>
        <span v-else-if="message.type === 'status' && message.value">{{ message.value }}</span>
        <span v-else>{{ message.value }}</span>
      </div>
      <div class="time" v-if="message && message.time">
        <div class="end-block-height">191157</div>
        <div class="end-time">大约 02D 02H 30M</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
    },
  },
  // mounted() {
  //   console.log('message', this.message);
  // },
  methods: {
    // 0x0dddd84abfa77192cf096bc9187da47e4f1f2a69
    link(message) {
      if (message.hash) {
        window.open(`https://www.blockchain.com/explorer/transactions/btc/${message.value}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.message-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .message-item-left {
    .label {
      font-size: 0.14rem;
      color: var(--gray-13);
    }
  }
  .message-item-right {
    .value {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 0.28rem;
      span {
        font-size: 0.14rem;
        color: var(--gray-1);
      }
      &.status {
        height: 0.28rem;
        line-height: 0.28rem;
        border-radius: 0.08rem;
        padding: 0 0.12rem;
        span {
          font-size: 0.12rem;
          text-align: center;
          color: var(--not-started-color);
        }
      }
      &.status-pending {
        background: #0ab671;
        span {
          color: #fff;
        }
      }
      &.status-not-started {
        background: var(--not-started-bg);
      }
      &.status-over {
        background: #e55036;
        span {
          color: #fff;
        }
      }
      &.hash {
        cursor: pointer;
        span {
          color: #2980fe;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .message-item {
    .message-item-left {
      .label {
        font-size: 0.28rem;
      }
    }
    .message-item-right {
      .value {
        span {
          font-size: 0.28rem;
        }
        &.status {
          height: 0.56rem;
          line-height: 0.56rem;
          padding: 0 0.2rem;
          border-radius: 0.16rem;
          span {
            font-size: 0.2rem;
          }
        }
      }
    }
  }
}
</style>
