<template>
  <div class="trading content">
    <div class="trading-wrap" v-if="tradingInfo">
      <div class="trading-info">
        <div class="text title">{{ $t('trading_info.title_3') }}</div>
        <div class="line-wrap">
          <div class="line"></div>
        </div>
        <div
          class="trading-list"
          :class="{ brc20: tokenDetail.token_protocol === 3 }"
        >
          <TradingItem
            v-for="(item, index) in trading24hList"
            :key="index"
            :tradingItem="item"
          />
          <SwapButton
            :tokenDetail="tokenDetail"
            v-if="tokenDetail.token_protocol !== 3"
          />
          <div
            class="brc20-trading-item-header"
            v-if="brc20TradingInfoList.length"
          >
            <div class="methods">
              <div class="title">{{ $t('brc20_trading_info.text_1') }}</div>
            </div>
            <div class="quantity">
              <div class="title">{{ $t('brc20_trading_info.text_2') }}</div>
            </div>
            <div class="from">
              <div class="title">{{ $t('brc20_trading_info.text_3') }}</div>
            </div>
            <div class="to">
              <div class="title">{{ $t('brc20_trading_info.text_4') }}</div>
            </div>
            <div class="date-time">
              <div class="title">{{ $t('brc20_trading_info.text_5') }}</div>
            </div>
          </div>
          <div class="brc20-trading-list" v-if="brc20TradingInfoList.length">
            <div
              class="brc20-trading-item"
              v-for="(item, index) in brc20TradingInfoList"
              :key="index"
            >
              <div class="methods">
                <div
                  class="text methods-text"
                  :class="{
                    deploy: item.deploy,
                    mint: item.mint,
                    receive: item.receive,
                    transfer: item.transfer,
                  }"
                >
                  {{ item.type }}
                </div>
              </div>
              <div class="quantity">
                <div class="text quantity-text">{{ item.amount }}</div>
                <div class="text quantity-time">
                  {{ item.blocktime.slice(-14) }}
                </div>
                <div class="text quantity-transition" v-if="!item.valid">
                  {{ $t('brc20_trading_info.text_6') }}
                </div>
              </div>
              <div class="from">
                <div
                  class="text from-text"
                  v-if="item.from"
                  @click="goUniSat(item.from)"
                >
                  {{ item.from.slice(0, 6) + '...' + item.from.slice(-6) }}
                </div>
                <div
                  class="text from-text-mobile"
                  v-if="item.from"
                  @click="goUniSat(item.from)"
                >
                  {{ item.from.slice(0, 3) + '...' + item.from.slice(-4) }}
                </div>
                <div class="text null-text" v-else>_</div>
              </div>
              <div class="to">
                <div
                  class="text to-text"
                  v-if="item.to"
                  @click="goUniSat(item.to)"
                >
                  {{ item.to.slice(0, 6) + '...' + item.to.slice(-6) }}
                </div>
                <div
                  class="text to-text-mobile"
                  v-if="item.to"
                  @click="goUniSat(item.to)"
                >
                  {{ item.to.slice(0, 3) + '...' + item.to.slice(-4) }}
                </div>
                <div class="text to-transition" v-if="!item.valid">
                  {{ $t('brc20_trading_info.text_6') }}
                </div>
              </div>
              <div class="date-time">
                <div class="text date-time-text">{{ item.blocktime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pool-container" v-if="pairList.length">
        <div class="pool-title">{{ $t('trading_info.title_2') }}</div>
        <div class="line"></div>
        <div class="pool-list">
          <div class="pool-list-header">
            <div class="dex">{{ $t('trading_info.text_7') }}</div>
            <div class="pair">{{ $t('trading_info.text_8') }}</div>
            <div class="liquidity">{{ $t('trading_info.text_9') }}</div>
          </div>
          <div class="line"></div>
          <div class="pool-item-wrap">
            <PoolItem
              v-for="(item, index) in pairList"
              :key="index"
              :poolItem="item"
            />
          </div>
        </div>
      </div>
      <div
        class="swap-button-wrap"
        v-if="tokenDetail.token_protocol !== 3"
      >
        <SwapButton :tokenDetail="tokenDetail" />
      </div>
    </div>
    <div class="loading-content" v-else-if="loading">
      <Loading />
    </div>
  </div>
</template>

<script>
import SwapButton from './SwapButton.vue';
import TradingItem from './TradingItem.vue';
import Loading from './Loading.vue';
import PoolItem from './PoolItem.vue';
import { TP_SERVER_DOMAIN } from '../utils/config';
import { parseWithUnit } from '../utils';
import dayjs from 'dayjs';
export default {
  props: {
    blockchain_id: {
      type: Number || String,
    },
    tokenDetail: {
      type: Object,
    },
    swapMarketConfig: {
      type: Object,
    },
    tokenInfo: {
      type: Object,
    },
  },
  components: { TradingItem, SwapButton, PoolItem, Loading },
  data() {
    return {
      tradingInfo: null,
      loading: false
    };
  },
  computed: {
    pairList() {
      if (
        this.tradingInfo &&
        this.tradingInfo.pairs &&
        Array.isArray(this.tradingInfo.pairs) &&
        this.swapMarketConfig[this.blockchain_id]
      ) {
        const dexObj =
          this.swapMarketConfig[this.blockchain_id].swap_router_map;
        const dexArr = Object.values(dexObj);
        const list = JSON.parse(JSON.stringify(this.tradingInfo.pairs));
        list.forEach((item, index) => {
          item.market_value = '$' + parseWithUnit(item.market_value);
          let arr = dexArr.filter((d) => {
            return d.icon === item.icon;
          });
          let arr1 = [];
          if (this.tokenInfo && Array.isArray(this.tokenInfo.dex)) {
            arr1 = this.tokenInfo.dex.filter((d) => {
              return d.pair === item.pair;
            });
          }
          let name = '';
          if (arr1[0]) {
            name = arr1[0].name;
          } else {
            name = arr[0] && arr[0].name;
          }
          item.name = name;
          if (index === 0) {
            item.isLargest = true;
          }
        });
        return list;
      } else {
        return [];
      }
    },
    trading24hList() {
      if (this.tradingInfo.volume_24h) {
        return [
          {
            title: '24h ' + this.$t('trading_info.text_1'),
            type: 'volume',
            amount:
              '$' + Number(this.tradingInfo.volume_24h).toLocaleString('en-US'),
          },
          {
            title: '24h ' + this.$t('trading_info.text_2'),
            type: 'address',
            amount: Number(
              this.tradingInfo.trading_address_count_24h
            ).toLocaleString('en-US'),
          },
          {
            title: '24h ' + this.$t('trading_info.text_3'),
            type: 'trade',
            amount: (
              Number(this.tradingInfo.trading_count_buy_24h) +
              Number(this.tradingInfo.trading_count_sell_24h)
            ).toLocaleString('en-US'),
            buy: Number(this.tradingInfo.trading_count_buy_24h).toLocaleString(
              'en-US'
            ),
            buyPercent:
              (Number(this.tradingInfo.trading_count_buy_24h) /
              (Number(this.tradingInfo.trading_count_buy_24h) +
                Number(this.tradingInfo.trading_count_sell_24h))).toFixed(2),
            sell: Number(
              this.tradingInfo.trading_count_sell_24h
            ).toLocaleString('en-US'),
          },
        ];
      } else {
        return [];
      }
    },
    brc20TradingInfoList() {
      if (
        this.tradingInfo.brc20_trading_info &&
        this.tradingInfo.brc20_trading_info.brc20_trading_records
      ) {
        this.tradingInfo.brc20_trading_info.brc20_trading_records.forEach(
          (item) => {
            if (item.blocktime) {
              item.blocktime = dayjs(item.blocktime * 1000).format(
                'YYYY/MM/DD HH:mm:ss'
              );
            } else {
              item.blocktime = this.$t('unconfirmed');
            }
            item.amount = parseWithUnit(parseFloat(item.amount));
            switch (item.type) {
              case 'inscribe-deploy':
                item.deploy = true;
                break;
              case 'inscribe-mint':
                item.mint = true;
                break;
              case 'inscribe-transfer':
                item.receive = true;
                break;
              case 'transfer':
                item.transfer = true;
                break;
              default:
                break;
            }
          }
        );
        return this.tradingInfo.brc20_trading_info.brc20_trading_records;
      } else {
        return [];
      }
    },
  },
  watch: {
    tokenDetail(nVal) {
      this.getTradingInfo(this.blockchain_id, nVal.contract);
    },
  },
  created() {
    // console.log('tokenDetail', this.tokenDetail);
    this.getTradingInfo(this.blockchain_id, this.tokenDetail.contract);
  },
  methods: {
    goUniSat(address) {
      window.open(
        `https://hk.unisat.io/brc20?q=${address}&tick=${this.tokenDetail.contract}`
      );
    },
    async getTradingInfo(blockchain_id, address) {
      this.loading = true
      try {
        const response = await fetch(
          `${TP_SERVER_DOMAIN}/v1/token/trading_info?blockchain_id=${blockchain_id}&address=${address}`
        );
        const res = await response.json();
        if (res.result === 0 && res.data) {
          this.tradingInfo = res.data;
        }
        this.loading = false
        this.$emit('tradingInfo', this.tradingInfo)
      } catch (error) {
        console.log(error);
        this.loading = false
        this.$emit('tradingInfo', this.tradingInfo)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.trading {
  margin-top: 0.11rem;
  padding-bottom: 0;
  border: none;
  .trading-wrap {
    // border: 0.01rem solid var(--border-2);
    border-radius: 0.2rem;
    padding-bottom: 0.2rem;
    .trading-info {
      .line-wrap {
        display: block;
        margin: 0 0.32rem;
      }
      .trading-list {
        margin: 0.32rem 0.32rem 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &.brc20 {
          flex-direction: column;
          padding-bottom: 0.36rem;
          .swap-button {
            display: none;
          }
        }
        .trading-item {
          width: 48%;
          &:nth-child(3) {
            width: 100%;
            margin-top: 0.2rem;
          }
        }
        .swap-button {
          display: none;
        }
        .title {
          font-size: 0.22rem;
          font-family: PingFang SC, PingFang SC-Regular;
          color: var(--gray-11);
          padding: 0;
        }
        .text {
          font-size: 0.26rem;
          font-family: PingFang SC, PingFang SC-Medium;
          text-align: left;
          color: var(--gray-1);
        }
        .brc20-trading-item-header {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          .from {
            .title {
              text-align: center;
            }
          }
          .to {
            width: auto;
            .title {
              text-align: center;
            }
          }
          .date-time {
            display: none;
          }
        }
        .brc20-trading-list {
          margin-top: 0.16rem;
          width: 100%;
          max-height: 7.5rem;
          overflow-y: auto;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }
          .brc20-trading-item {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            width: 100%;
            &:nth-child(n + 2) {
              margin-top: 0.48rem;
            }
            .methods {
              display: flex;
              align-items: center;
              .methods-text {
                width: fit-content;
                background: #2980fe;
                border-radius: 0.08rem;
                padding: 0.08rem 0.12rem;
                font-size: 0.2rem;
                color: #ffffff;
                line-height: 0.22rem;
                height: auto;
                max-width: 1.14rem;
                &.deploy {
                  background: #fcb244;
                }
                &.transfer {
                  background: #03c288;
                }
                &.receive {
                  background: #79889e;
                }
              }
            }
            .quantity {
              display: flex;
              flex-direction: column;
              // align-items: center;
              .quantity-text {
                overflow-wrap: anywhere;
                overflow: hidden;
              }
              .quantity-time {
                font-size: 0.18rem;
                font-family: PingFang SC, PingFang SC-Regular;
                color: var(--gray-1);
              }
              .quantity-transition {
                display: none;
              }
            }
            .from {
              display: flex;
              align-items: center;
              justify-content: center;
              .from-text {
                display: none;
              }
              .from-text-mobile {
                text-align: center;
                text-decoration: underline;
              }
              .null-text {
                text-align: center;
              }
            }
            .to {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              justify-content: center;
              width: auto;
              .to-text {
                display: none;
              }
              .to-text-mobile {
                text-align: center;
                text-decoration: underline;
              }
              .to-transition {
                display: block;
                font-size: 0.18rem;
                font-family: PingFang SC, PingFang SC-Regular;
                color: #e55036;
              }
            }
            .date-time {
              display: none;
              .date-time-text {
                text-align: right;
                font-family: PingFang SC, PingFang SC-Regular;
              }
            }
          }
        }
      }
    }
    .pool-container {
      margin: 0.36rem 0.32rem 0;
      .pool-title {
        margin-bottom: 0.28rem;
        font-size: 0.28rem;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: var(--gray-15);
      }
      .pool-list {
        margin-top: 0.36rem;
        .pool-list-header {
          display: flex;
          align-items: center;
          div {
            font-size: 0.22rem;
            font-family: PingFang SC, PingFang SC-Regular;
            color: var(--gray-11);
          }
          .dex {
            flex: 1;
          }
          .pair {
            flex: 1;
          }
          .liquidity {
            flex: 1;
            text-align: right;
          }
        }
        .line {
          display: none;
        }
        .pool-item-wrap {
          margin-top: 0.36rem;
          .pool-item:nth-child(n + 2) {
            margin-top: 0.36rem;
          }
        }
      }
    }
    .swap-button-wrap {
      display: flex;
      margin: 0.32rem 0.32rem 0;
    }
  }
  .loading-content {
    display: block;
  }
}
@media screen and (min-width: 767px) {
  .trading {
    padding: 0;
    box-shadow: none;
    .trading-wrap {
      border: none;
      padding-bottom: 0;
      .trading-info {
        border: 0.01rem solid var(--border-3);
        border-radius: 0.12rem;
        padding: 0.16rem;
        .title {
          padding: 0;
          font-size: 0.18rem;
        }
        .line-wrap {
          display: none;
        }
        .trading-list {
          margin: 0.16rem 0 0;
          &.brc20 {
            padding: 0;
            .swap-button {
              display: none;
            }
          }
          .trading-item {
            &:nth-child(1) {
              width: 25%;
            }
            &:nth-child(2) {
              width: 18%;
            }
            &:nth-child(3) {
              margin-top: 0;
              // width: 25%;
              width: 37%;
            }
          }
          :nth-child(4) {
            width: 15%;
          }
          .swap-button {
            display: flex;
          }
          .title {
            font-size: 0.13rem;
          }
          .text {
            font-size: 0.15rem;
            text-align: left;
          }
          .brc20-trading-item-header {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1.4fr;
            .from {
              .title {
                text-align: center;
              }
            }
            .to {
              width: auto;
              .title {
                text-align: center;
              }
            }
            .date-time {
              display: block;
              .title {
                text-align: right;
              }
            }
          }
          .brc20-trading-list {
            margin-top: 0.16rem;
            width: 100%;
            max-height: 2.8rem;
            overflow-y: auto;
            scrollbar-width: none;
            &::-webkit-scrollbar {
              display: none;
            }
            .brc20-trading-item {
              grid-template-columns: 1fr 1fr 1fr 1fr 1.4fr;
              &:nth-child(n + 2) {
                margin-top: 0.24rem;
              }
              .methods {
                .methods-text {
                  padding: 0.06rem 0.12rem;
                  font-size: 0.12rem;
                  line-height: 0.14rem;
                  max-width: 0.78rem;
                }
              }
              .quantity {
                .quantity-time {
                  display: none;
                }
                .quantity-transition {
                  display: block;
                  font-size: 0.12rem;
                  font-family: PingFang SC, PingFang SC-Regular;
                  color: #e55036;
                }
              }
              .from {
                .from-text {
                  display: block;
                  text-align: center;
                  text-decoration: underline;
                  cursor: pointer;
                }
                .from-text-mobile {
                  display: none;
                }
              }
              .to {
                width: auto;
                .to-text {
                  display: block;
                  text-align: center;
                  text-decoration: underline;
                  cursor: pointer;
                }
                .to-text-mobile {
                  display: none;
                }
                .to-transition {
                  display: none;
                }
              }
              .date-time {
                display: flex;
                align-items: center;
                justify-content: flex-end;
              }
            }
          }
        }
      }
      .pool-container {
        // margin: 32px 20px 0;
        border: 0.01rem solid var(--border-3);
        border-radius: 0.12rem;
        margin: 0.16rem 0 0 0;
        .pool-title {
          font-size: 0.18rem;
          margin-bottom: 0;
          padding: 0.16rem 0 0 0.16rem;
        }
        .pool-list {
          margin-top: 0;
          // border: 1px solid var(--border-3);
          border-radius: 0.12rem;
          .pool-list-header {
            padding: 0.16rem 0.2rem;
            div {
              font-size: 0.17rem;
            }
          }
          .line {
            display: none;
          }
          .pool-item-wrap {
            padding: 0 0.2rem 0.2rem;
            margin-top: 0;
            .pool-item:nth-child(n + 2) {
              margin-top: 0.24rem;
            }
          }
        }
        .line {
          display: none;
        }
      }
      .swap-button-wrap {
        display: none;
      }
    }
    .loading-content {
      display: none;
    }
  }
}
@media screen and (min-width: 1400px) {
  .trading {
    padding: 0;
    .trading-wrap {
      .trading-info {
        border: 0.01rem solid var(--border-3);
        border-radius: 0.12rem;
        padding: 0.16rem;
        .title {
          padding: 0;
          font-size: 0.16rem;
        }
        .line-wrap {
          display: none;
        }
        .trading-list {
          margin: 0.16rem 0 0;
          // .trading-item {
          //   &:nth-child(1) {
          //     width: 30%;
          //   }
          //   &:nth-child(2) {
          //     width: 20%;
          //   }
          //   &:nth-child(3) {
          //     margin-top: 0;
          //     width: 25%;
          //   }
          // }
          // :nth-child(4) {
          //   width: 20%;
          // }
          .swap-button {
            display: flex;
          }
        }
      }
      .pool-container {
        // margin: 32px 20px 0;
        border: 0.01rem solid var(--border-3);
        border-radius: 0.12rem;
        margin: 0.16rem 0 0 0;
        .pool-title {
          font-size: 0.16rem;
          margin-bottom: 0;
          padding: 0.16rem 0 0 0.16rem;
        }
        .pool-list {
          margin-top: 0;
          // border: 1px solid var(--border-3);
          border-radius: 0.12rem;
          .pool-list-header {
            padding: 0.16rem 0.2rem;
            div {
              font-size: 0.13rem;
            }
          }
          .line {
            display: none;
          }
          .pool-item-wrap {
            padding: 0 0.2rem 0.2rem;
            margin-top: 0;
            .pool-item:nth-child(n + 2) {
              margin-top: 0.24rem;
            }
          }
        }
        .line {
          display: none;
        }
      }
      .swap-button-wrap {
        display: none;
      }
    }
    .loading-content {
      display: none;
    }
  }
}
</style>
