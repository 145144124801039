<template>
  <div class="content base-content">
    <div class="text title">{{ $t('base_info.title') }}</div>
    <div class="line-wrap">
      <div class="line"></div>
    </div>
    <div
      class="baseInfo-wrap"
      :class="{ 'no-description': tokenDetail.description === '' }"
    >
      <!-- <div class="baseInfo d-flex ai-center jc-between">
          <div class="text name">
            {{ $t('projectName') }}
          </div>
          <div class="text word-break flex-1">{{ tokenDetail.name }}</div>
        </div> -->
      <div class="baseInfo d-flex jc-between">
        <div class="text name">
          {{
            tokenDetail.token_protocol === 3
              ? $t('base_info.inscriptionID')
              : $t('base_info.contract')
          }}
        </div>
        <div
          class="d-flex ai-center"
          v-if="tokenDetail.contract && tokenDetail.token_protocol !== 3"
        >
          <div
            class="text blue-text"
            @click="onBlockchainBrowser(tokenDetail, false, 'contract')"
          >
            <span v-if="tokenDetail.contract.length > 24">
              {{
                tokenDetail.contract.slice(0, 10) +
                '...' +
                tokenDetail.contract.slice(-10)
              }}
            </span>
            <span v-else>{{ tokenDetail.contract }}</span>
          </div>
          <img
            class="copy"
            src="../assets/copy.png"
            @click="onCopy(tokenDetail.contract, true)"
          />
        </div>
        <div
          class="d-flex ai-center"
          v-else-if="
            tokenDetail.token_market_info &&
            tokenDetail.token_market_info.inscription_id
          "
        >
          <div
            class="text blue-text"
            @click="onBlockchainBrowser(tokenDetail, true)"
          >
            {{
              tokenDetail.token_market_info.inscription_id.slice(0, 10) +
              '...' +
              tokenDetail.token_market_info.inscription_id.slice(-10)
            }}
          </div>
          <img
            class="copy"
            src="../assets/copy.png"
            @click="onCopy(tokenDetail.token_market_info.inscription_id, true)"
          />
        </div>
        <div v-else class="text">~</div>
      </div>
      <div class="baseInfo d-flex jc-between">
        <div class="text name">
          {{ isTon ? $t('base_info.admin') : $t('base_info.issuer') }}
        </div>
        <div class="d-flex ai-center" v-if="tokenDetail.issuer">
          <div
            class="text blue-text"
            @click="onBlockchainBrowser(tokenDetail, false, 'issuer')"
          >
            {{
              tokenDetail.issuer.slice(0, 10) +
              '...' +
              tokenDetail.issuer.slice(-10)
            }}
          </div>
          <img
            class="copy"
            src="../assets/copy.png"
            @click="onCopy(tokenDetail.issuer)"
          />
        </div>
        <div v-else class="text">~</div>
      </div>
      <div class="baseInfo d-flex jc-between">
        <div class="text name">
          {{ $t('base_info.network') }}
        </div>
        <div class="text" v-if="tokenDetail.blockchain_id">
          {{ networkText }}
        </div>
        <div v-else class="text">~</div>
      </div>
      <div class="baseInfo d-flex jc-between">
        <div class="text name">
          {{ isTon ? $t('base_info.issue_Time') : $t('base_info.issue_Time') }}
        </div>
        <div
          class="d-flex ai-center"
          v-if="tokenDetail.issue_time && tokenDetail.issue_time != '0'"
        >
          <div class="text">
            {{ tokenDetail.issue_time }}
          </div>
        </div>
        <div v-else class="text">~</div>
      </div>
      <div class="baseInfo socialInfo d-flex jc-between">
        <div class="text name">
          {{ $t('base_info.links') }}
        </div>
        <div
          class="d-flex ai-center flex-wrap jc-end flex-1"
          v-if="socialList.length"
        >
          <img
            class="social"
            v-for="item in socialList"
            :src="item.icon"
            :key="item.icon"
            @click="goSocial(item)"
          />
        </div>
        <div class="text d-flex a-center" v-else>~</div>
      </div>
    </div>
    <div class="text description-wrap" v-show="description">
      <div class="multi-text" :class="{ strMore: isStrMore }" ref="multiStr">
        {{ description }}<span v-if="isSuffix && !isStrMore">....&nbsp;</span>
        <span
          class="text more"
          :class="{ dark: isDark }"
          v-if="isSuffix && !isStrMore"
          @click="reset"
        >
          {{ $t('more') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    tokenDetail: {
      type: Object,
    },
    socialList: {
      type: Array,
    },
    account_url: {
      type: String,
    },
    token_url: {
      type: String,
    },
  },
  data() {
    return {
      isSuffix: false,
      isStrMore: false,
      description: '',
      originDesc: '',
      count: 0,
    };
  },
  computed: {
    ...mapGetters([
      'isDark',
      'isTon',
      'blockchainList_ZH',
      'blockchainList_EN',
    ]),
    issuerImg() {
      let imgStatus = '';
      if (this.isDark) {
        imgStatus = '-dark';
      }
      return require(`../assets/copy-black${imgStatus}.png`);
    },
    networkText() {
      let blockchainList = this.blockchainList_EN;
      if (this.$i18n.locale === 'zh') {
        blockchainList = this.blockchainList_ZH;
      }
      if (this.tokenDetail && this.tokenDetail.blockchain_id) {
        const network = blockchainList.find(
          (item) => item.hid === this.tokenDetail.blockchain_id
        );
        if (network) {
          return network.title;
        } else {
          return '';
        }
      } else {
        return '';
      }
    },
  },
  created() {},
  mounted() {
    this.description = JSON.parse(JSON.stringify(this.tokenDetail.description));
    this.originDesc = JSON.parse(JSON.stringify(this.tokenDetail.description));
    this.computeDesc();
  },
  methods: {
    reset() {
      this.description = this.originDesc;
      this.isStrMore = true;
    },
    goSocial(item) {
      this.$emit('goSocial', item);
    },
    onCopy(text, flag) {
      this.$emit('onCopy', text, flag);
    },
    onBlockchainBrowser(token, isInscriptionId, type = 'contract') {
      // console.log('token', token);
      let contract1 = token.contract;
      if (token.blockchain_id == 7) {
        // iost 代币信息使用 bl_symbol 来跳转
        contract1 = token.symbol.toLowerCase();
      }
      if (token.token_protocol === 3) {
        contract1 = token.token_market_info.inscription_id;
        let lang = 'en';
        if (this.$i18n.locale === 'zh') {
          lang = 'cn';
        }
        if (isInscriptionId) {
          window.open(
            `https://btc.tokenview.io/${lang}/inscription/${contract1}`
          );
        } else {
          contract1 = token.issuer;
          window.open(`https://btc.tokenview.io/${lang}/address/${contract1}`);
        }
      } else {
        if (type === 'issuer') {
          contract1 = token.issuer;
        }
        if (this.token_url) {
          window.open(this.token_url.replace('{account}', contract1));
        }
      }
    },
    // 计算 description 的高度
    getDescHeight() {
      let heightObj = {};
      this.$nextTick(() => {
        let multi = this.$refs.multiStr;
        // console.log(multi)
        if (multi) {
          // console.log('scrollHeight', multi.scrollHeight)
          // console.log('clientHeight', multi.clientHeight)
          heightObj.scrollHeight = multi.scrollHeight;
          heightObj.clientHeight = multi.clientHeight;
          // if (Platform.isIos) {
          //   heightObj.clientHeight = multi.clientHeight + 1
          // }
        }
      });
      return heightObj;
    },
    // 从后往前删减 description 的字符
    async computeDesc() {
      const heightObj = await this.getDescHeight();
      // console.log('height', heightObj)
      const { scrollHeight, clientHeight } = heightObj;
      if (this.count === 0 && scrollHeight === clientHeight) {
        this.isStrMore = true;
        return;
      }
      // 超过四行 48639872   刚好四行 48566176 48565151 少于四行 48583487
      if (scrollHeight > clientHeight) {
        this.description = this.description.slice(
          0,
          this.description.length - 1
        );
        // console.log('description', this.tokenDetail.description)
        this.count++;
        this.isSuffix = true;
        this.computeDesc();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.base-content {
  margin-top: 0.32rem;
  .line-wrap {
    margin: 0 0.32rem;
  }
  .give-up-ownership {
    margin-left: 0.1rem;
    font-size: 0.26rem;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: var(--gray-3);
  }
  .baseInfo-wrap {
    padding: 0.24rem 0.32rem 0.32rem;
    &.no-description {
      // padding: 0.24rem 0.32rem 0 0.32rem;
    }
  }
  .baseInfo {
    // padding: 0 0.32rem;
    &:nth-child(n + 2) {
      margin-top: 0.16rem;
    }
    .word-break {
      margin-left: 0.5rem;
      text-align: right;
      word-break: break-all;
    }
    .issuer-text {
      text-align: right;
      word-break: break-all;
    }
    .copy {
      margin-left: 0.18rem;
      width: 0.32rem;
      height: 0.32rem;
    }
    .social {
      margin: 0.05rem 0 0.05rem 0.24rem;
      width: 0.32rem;
      height: 0.32rem;
    }
  }
  .detail {
    padding: 0.25rem 0.32rem 0 0.32rem;
    position: relative;
    .desc {
      max-height: 1.44rem;
      overflow: hidden;
      line-height: 0.36rem;

      &.more {
        max-height: none;
      }
    }

    .button {
      position: absolute;
      right: 0.32rem;
      bottom: 0;
      background: #fff;
      color: #2980fe;
      text-align: right;
      &.dark {
        background: #18191c;
        color: #236fdb;
      }
    }
  }
  .description-wrap {
    // padding: 0.25rem 0.32rem 0 0.32rem;
    margin: 0.04rem 0.32rem 0.32rem 0.32rem;
    padding: 0.2rem 0.24rem;
    background: var(--bg-10);
    border-radius: 0.16rem;
    .multi-text {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      word-break: break-all;
      // line-height: 0.36rem;
      font-size: 0.22rem;
      &.strMore {
        -webkit-line-clamp: 999;
      }
      .more {
        color: var(--blue-1);
      }
    }
  }
}
@media screen and (min-width: 767px) {
  .base-content {
    margin-top: 0;
    border: none;
    box-shadow: none;
    border-radius: 0;
    background: none;
    padding-bottom: 0.12rem;
    .line-wrap {
      display: none;
    }
    .baseInfo-wrap {
      padding: 0 0.2rem;
    }
    .baseInfo {
      .copy {
        margin-left: 0.06rem;
        width: 0.18rem;
        height: 0.18rem;
      }
      .social {
        margin: 0.05rem 0 0.05rem 0.12rem;
        width: 0.18rem;
        height: 0.18rem;
        cursor: pointer;
      }
    }
    .description-wrap {
      margin: 0.18rem 0.2rem 0;
      padding: 0.12rem 0.16rem;
      border-radius: 0.12rem;
      .multi-text {
        font-size: 0.14rem;
      }
    }
  }
}
@media screen and (min-width: 1400px) {
  .base-content {
    border: none;
    box-shadow: none;
    border-radius: 0;
    background: none;
    padding-bottom: 0.12rem;
    .line-wrap {
      display: none;
    }
    .baseInfo-wrap {
      padding: 0 0.2rem;
    }
    .baseInfo {
      .copy {
        margin-left: 0.06rem;
        width: 0.16rem;
        height: 0.16rem;
      }
      .social {
        margin: 0.05rem 0 0.05rem 0.12rem;
        width: 0.16rem;
        height: 0.16rem;
        cursor: pointer;
      }
    }
    .description-wrap {
      margin: 0.18rem 0.2rem 0;
      padding: 0.12rem 0.16rem;
      border-radius: 0.12rem;
      .multi-text {
        font-size: 0.12rem;
      }
    }
  }
}
</style>
