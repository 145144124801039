<template>
  <div class="skeleton-mobile">
    <div class="skeleton-header">
      <SkeletonCircleBlock />
      <SkeletonBlock height="0.74rem" />
    </div>
    <div class="skeleton-wrap">
      <div class="skeleton-item">
        <SkeletonBlock width="40%" />
        <div class="line"></div>
        <div class="long-wrap">
          <SkeletonBlock v-for="(cItem, cIndex) in 5" :key="cIndex" />
        </div>
      </div>
      <div class="skeleton-item">
        <SkeletonBlock width="40%" />
        <div class="line"></div>
        <div class="long-wrap">
          <SkeletonBlock v-for="(cItem, cIndex) in 6" :key="cIndex" />
        </div>
      </div>
      <div class="tabs-wrap">
        <SkeletonBlock width="70%" />
      </div>
      <div class="skeleton-item">
        <SkeletonBlock width="40%" />
        <div class="line"></div>
        <div class="long-wrap">
          <SkeletonBlock v-for="(cItem, cIndex) in 5" :key="cIndex" />
        </div>
      </div>
    </div>
    <div class="tools-wrap">
      <SkeletonBlock width="50%"/>
      <div class="circle-block">
        <SkeletonCircleBlock radius="0.24rem" v-for="(item,index) in 4" :key="index" />
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonBlock from './SkeletonBlock.vue';
import SkeletonCircleBlock from './SkeletonCircleBlock.vue';
export default {
  components: { SkeletonBlock, SkeletonCircleBlock },
};
</script>

<style lang="scss" scoped>
.skeleton-mobile {
  display: none;
}
@media screen and (max-width: 767px) {
  .skeleton-mobile {
    display: block;
    background: var(--bg-1);
    margin: 0 0.32rem;
    padding-top: 0.5rem;
    .line {
      height: 0.01rem;
      background: var(--border-2);
    }
    .skeleton-header {
      display: flex;
      align-items: center;
      .skeleton-block {
        margin-left: 0.24rem;
        flex: 1;
      }
    }
    .skeleton-wrap {
      margin-top: 0.48rem;
      .skeleton-item {
        padding: 0.24rem 0.32rem 0.45rem;
        background: var(--bg-1);
        border: 0.01rem solid var(--border-2);
        border-radius: 0.2rem;
        box-shadow: 0 0.06rem 0.3rem 0 rgba(0, 0, 0, 0.08);
        &:nth-child(n+2) {
          margin-top: 0.32rem;
        }
        .line {
          margin-top: 0.2rem;
        }
        .long-wrap {
          margin-top: 0.32rem;
          .skeleton-block {
            &:nth-child(n + 2) {
              margin-top: 0.4rem;
            }
          }
        }
      }
      .tabs-wrap {
        margin-top: 0.6rem;
      }
    }
    .tools-wrap {
      margin: 0.44rem 0.32rem 1.44rem;
      .circle-block {
        margin-top: 0.32rem;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
