<template>
  <div class="trading-item">
    <div class="trading-item-title">{{ tradingItem.title }}</div>
    <div class="trading-amount-wrap">
      <div class="total-amount">{{ tradingItem.amount }}</div>
      <div
        class="sell-buy-amount"
        v-if="tradingItem.type === 'trade'"
      >
        <div class="percent-bar">
          <div
            class="buy-bar"
            :style="{ width: tradingItem.buyPercent * 100 + '%' }"
          ></div>
          <div
            class="sell-bar"
            :style="{ width: 100 - tradingItem.buyPercent * 100 + '%' }"
          ></div>
        </div>
        <div class="sell-buy-wrap">
          <div class="buy">
            <span class="label">{{ $t('trading_info.text_4') }}:</span>
            <span class="amount">{{ tradingItem.buy }}</span>
          </div>
          <div class="buy sell">
            <span class="label">{{ $t('trading_info.text_5') }}:</span>
            <span class="amount">{{ tradingItem.sell }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tradingItem: {},
  },
};
</script>

<style lang="scss" scoped>
.trading-item {
  padding: 0.2rem 0.32rem;
  // border: 0.01rem solid var(--border-3);
  background: var(--bg-10);
  border-radius: 0.16rem;
  .trading-item-title {
    font-size: 0.2rem;
    font-family: PingFang SC, PingFang SC-Regular;
    color: var(--gray-11);
  }
  .trading-amount-wrap {
    margin-top: 0.05rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .total-amount {
      font-size: 0.26rem;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: var(--gray-1);
      word-break: break-all;
    }
    .sell-buy-amount {
      .percent-bar {
        width: 100%;
        display: flex;
        .buy-bar {
          width: 50%;
          height: 0.12rem;
          background: #03c288;
          border-radius: 0.06rem 0 0 0.06rem;
        }
        .sell-bar {
          width: 50%;
          height: 0.12rem;
          background: #e55036;
          border-radius: 0 0.06rem 0.06rem 0;
        }
      }
      .sell-buy-wrap {
        margin-top: 0.02rem;
        display: flex;
        span {
          font-size: 0.24rem;
          font-family: PingFang SC, PingFang SC-Regular;
          color: var(--gray-1);
        }
        .amount {
          margin-left: 0.05rem;
        }
        .buy {
          display: flex;
          align-items: center;
          .label {
            color: #03c288;
          }
        }
        .sell {
          margin-left: 1.7rem;
          .label {
            color: #ed4c56;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 767px) {
  .trading-item {
    padding: 0.13rem 0.2rem;
    border-radius: 0.12rem;
    .trading-item-title {
      font-size: 0.14rem;
    }
    .trading-amount-wrap {
      margin-top: 0.1rem;
      .total-amount {
        font-size: 0.18rem;
      }
      .sell-buy-amount {
        margin-top: 0;
        .percent-bar {
          top: -0.08rem;
          .buy-bar {
            height: 0.06rem;
            border-radius: 0.03rem 0 0 0.03rem;
          }
          .sell-bar {
            height: 0.06rem;
            border-radius: 0 0.03rem 0.03rem 0;
          }
        }
        .sell-buy-wrap {
          span {
            font-size: 0.12rem;
            transform: scale(0.92);
          }
          .sell {
            margin-left: 0.35rem;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1400px) {
  .trading-item {
    padding: 0.13rem 0.2rem;
    border-radius: 0.12rem;
    .trading-item-title {
      width: fit-content;
      font-size: 0.12rem;
      transform: scale(0.9);
      margin-left: -0.03rem;
    }
    .trading-amount-wrap {
      margin-top: 0.1rem;
      .total-amount {
        font-size: 0.16rem;
      }
      .sell-buy-amount {
        span {
          font-size: 0.15rem;
        }
      }
    }
  }
}
</style>
