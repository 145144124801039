<template>
  <div class="home">
    <div class="header__search-container">
      <Header />
      <div class="search-wrap">
        <SearchInput @getSearchParams="getSearchParams" />
        <div class="search-button" @click="onSearch">搜索</div>
        <div
          class="search-result-content"
          :class="{ visibility: searchStatus !== '' || resultList.length }"
        >
          <div class="search-loading" v-if="searchStatus === 'loading'">
            <img :src="loadingImg" alt="" />
          </div>
          <div class="no-search-result" v-else-if="searchStatus === 'noData'">
            <img :src="noDataImg" alt="" />
            <span>{{ $t('no_data') }}</span>
          </div>
          <div class="search-result-list" v-else>
            <TokenItem
              v-for="(item, index) in resultList"
              :key="index"
              :token="item"
              @getToken="getToken"
            />
          </div>
        </div>
        <img class="token-info-header" :src="tokenInfoHeaderImg" alt="" />
      </div>
    </div>
    <div class="mobile-container" v-if="!isLoading && isMobileSize && !isRune">
      <div class="base-token-info content">
        <TokenInfo :tokenDetail="tokenDetail" :isBRC20="isBRC20" />
        <!-- 基本信息 -->
        <BaseInfo
          v-if="tokenDetail.blockchain_id"
          :tokenDetail="tokenDetail"
          :socialList="socialList"
          @onCopy="getCopy"
          @goSocial="goSocial"
          @onBlockchainBrowser="goBlockchainBrowser"
          :token_url="token_url"
        />
      </div>
      <!-- 代币概述 -->
      <Overview
        v-if="overviewList1.length"
        :overviewList1="overviewList1"
        :overviewList2="overviewList2"
      />
      <div
        class="token-info-tabs content"
        :class="{
          'brc20-trading': isBRC20 && tokenInfoTabType === 'trading_info',
          'token-trading': tokenInfoTabType === 'trading_info',
        }"
        v-if="tokenDetail.blockchain_id"
      >
        <!-- <Tabs :tabList="tabList" :curType="tokenInfoTabType" @getTab="onTab" /> -->
        <MobileTabs
          :tabList="tabList"
          :curType="tokenInfoTabType"
          @getTab="onTab"
        />
        <!-- Holders -->
        <Holders
          v-if="tokenDetail.blockchain_id && tokenInfoTabType === 'holder_info'"
          :blockchain_id="tokenDetail.blockchain_id"
          :contract="tokenDetail.contract"
          :symbol="tokenDetail.symbol"
          :account_url="account_url"
          :isBRC20="isBRC20"
        />
        <!-- 交易信息 -->
        <Trading
          v-if="
            tokenDetail.blockchain_id && tokenInfoTabType === 'trading_info'
          "
          :blockchain_id="tokenDetail.blockchain_id"
          :tokenDetail="tokenDetail"
          :swapMarketConfig="swapMarketConfig"
          :tokenInfo="tokenInfo"
          @tradingInfo="getTradingInfo"
        />
        <!-- 安全信息 -->
        <SecurityInfo
          v-if="
            tokenInfo &&
            isSupport &&
            contract &&
            tokenInfoTabType === 'security'
          "
          :securityList="securityList"
          :attention="attention"
          :risky="risky"
          :isHoleAddress="isHoleAddress"
          :isRiskyAttention="isRiskyAttention"
          @goTokenDetection="goTokenDetection"
        />
        <div
          class="root-data-container"
          v-if="originTeamList.length || investorsList.length"
        >
          <RootData
            v-show="
              tokenDetail.blockchain_id && tokenInfoTabType === 'root_data'
            "
            :contract_address="tokenDetail.contract"
            :teamList="teamList"
            :originTeamList="originTeamList"
            :investorsList="investorsList"
            :originInvestorsList="originInvestorsList"
            :total_funding="total_funding"
            @showAll="showAll"
            :loading="rootDataLoading"
          />
        </div>
        <NoRootData
          v-else-if="
            tokenInfoTabType === 'root_data' ||
            (tokenInfoTabType === 'trading_info' && !tradingInfo)
          "
          :noDataType="tokenInfoTabType === 'root_data' ? 'root-data' : 'trading'"
        />
      </div>
      <!-- <div
        v-else-if="
          isLoading && isSupport && contract && tokenInfoTabType === 0
        "
        class="loading-wrap"
      >
        <img src="../assets/loading.png" alt="" />
      </div> -->
      <Tools v-if="tokenDetail.blockchain_id" :isBRC20="isBRC20" />
    </div>
    <div
      class="pc-container"
      v-if="tokenDetail.blockchain_id && !isLoading && !isMobileSize && !isRune"
    >
      <div class="pc-container-left">
        <div class="baseInfo-overview">
          <!-- 基本信息 -->
          <TokenInfo :tokenDetail="tokenDetail" :isBRC20="isBRC20" />
          <div class="line"></div>
          <BaseInfo
            v-if="tokenDetail.blockchain_id"
            :tokenDetail="tokenDetail"
            :socialList="socialList"
            @onCopy="getCopy"
            @goSocial="goSocial"
            @onBlockchainBrowser="goBlockchainBrowser"
            :token_url="token_url"
          />
          <!-- 代币概述 -->
          <Overview
            v-if="overviewList1.length"
            :overviewList1="overviewList1"
            :overviewList2="overviewList2"
          />
        </div>
        <!-- 安全信息 -->
        <SecurityInfo
          v-if="tokenInfo && isSupport && contract"
          :securityList="securityList"
          :attention="attention"
          :risky="risky"
          :isHoleAddress="isHoleAddress"
          :isRiskyAttention="isRiskyAttention"
          @goTokenDetection="goTokenDetection"
        />
      </div>
      <div class="pc-container-right">
        <!-- Holders -->
        <Holders
          v-if="tokenDetail.blockchain_id"
          :blockchain_id="tokenDetail.blockchain_id"
          :contract="tokenDetail.contract"
          :symbol="tokenDetail.symbol"
          :account_url="account_url"
          :isBRC20="isBRC20"
        />
        <!-- 交易信息 -->
        <div class="trading-root_data-content">
          <div class="tab-list-content">
            <div class="tab-list">
              <div
                class="tab-item"
                :class="{ active: tradingRootDataType === item.type }"
                v-for="(item, index) in tradingRootDataTabList"
                @click="onTradingRootDataTab(item)"
                :key="index"
              >
                <div class="tab-title">{{ item.tab }}</div>
              </div>
            </div>
            <div
              class="root_data-power"
              v-if="tradingRootDataType === 'Team-financing'"
            >
              <RootDataImg />
            </div>
          </div>
          <Trading
            v-if="
              tokenDetail.blockchain_id && tradingRootDataType === 'Trading'
            "
            :blockchain_id="tokenDetail.blockchain_id"
            :tokenDetail="tokenDetail"
            :swapMarketConfig="swapMarketConfig"
            :tokenInfo="tokenInfo"
            @tradingInfo="getTradingInfo"
          />
          <div
            class="root-data-container"
            v-if="originTeamList.length || investorsList.length"
          >
            <RootData
              v-show="
                tokenDetail.blockchain_id &&
                tradingRootDataType === 'Team-financing'
              "
              :contract_address="tokenDetail.contract"
              :teamList="teamList"
              :originTeamList="originTeamList"
              :investorsList="investorsList"
              :originInvestorsList="originInvestorsList"
              :total_funding="total_funding"
              @showAll="showAll"
              :loading="rootDataLoading"
            />
          </div>
          <NoRootData
            v-else-if="
              tradingRootDataType === 'Team-financing' ||
              (tradingRootDataType === 'Trading' && !tradingInfo)
            "
            :noDataType="
              tradingRootDataType === 'Trading' ? 'trading' : 'root-data'
            "
          />
        </div>
        <div class="ipad-tools">
          <Tools v-if="tokenDetail.blockchain_id" :isBRC20="isBRC20" />
        </div>
      </div>
    </div>
    <Rune
      v-if="isRune"
      :rune="rune"
      :runeStatus="runeStatus"
      :baseInfoLabel="baseInfoLabel"
      :minDataLabel="minDataLabel"
    />
    <div class="fixed-tools" v-if="!isRune">
      <Tools v-if="tokenDetail.blockchain_id" :isBRC20="isBRC20" />
    </div>
    <SkeletonMobile v-if="isLoading" />
    <div class="pc-loading-content" v-if="isLoading">
      <img :src="loadingImg" alt="" />
    </div>
    <Toast
      v-if="isToast"
      :isCopyContract="isCopyContract"
      :toastTitleObj="toastTitleObj"
      :isBRC20="isBRC20"
      @closeToast="closeToast"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import _ from 'lodash';
import Overview from '../components/Overview.vue';
import Holders from '../components/Holders.vue';
import Trading from '../components/Trading.vue';
// import Tabs from '../components/Tabs.vue';
import MobileTabs from '../components/MobileTabs.vue';
import Tools from '../components/Tools.vue';
import BaseInfo from '../components/BaseInfo.vue';
import SecurityInfo from '../components/SecurityInfo.vue';
import RootData from '../components/RootData.vue';
import NoRootData from '../components/NoRootData.vue';
import SkeletonMobile from '../components/SkeletonMobile.vue';
import Header from '../components/layout/Header.vue';
import SearchInput from '../components/common/SearchInput.vue';
import TokenItem from '../components/common/TokenItem.vue';
import TokenInfo from '../components/TokenInfo.vue';
import Rune from '../components/layout/Rune.vue';
import RootDataImg from '../components/RootDataImg.vue';

import { GlobalMixins } from '../mixins';
import darkModeImg from '../mixins/darkModeImg';
import Big from 'big.js';
import {
  NodeMap,
  setMode,
  parsePrice,
  parseWithUnit,
  TRANSIT_NS_CHAIN_ID_MAP,
  truncateDecimals,
  tokenSearch,
} from '../utils';
import { TP_SERVER_DOMAIN, TP_RUNE_DOMAIN } from '../utils/config';

const DOMAIN = 'https://tp-lab.tokenpocket.pro/report/index.html';

export default {
  name: 'Token',
  components: {
    Header,
    Rune,
    SearchInput,
    TokenItem,
    TokenInfo,
    Overview,
    Holders,
    // Tabs,
    MobileTabs,
    Trading,
    Tools,
    BaseInfo,
    SecurityInfo,
    RootData,
    NoRootData,
    SkeletonMobile,
    RootDataImg,
  },
  mixins: [GlobalMixins, darkModeImg],
  data() {
    return {
      tradingInfo: null,
      isRune: false,
      searchStatus: '',
      inputVal: '',
      resultList: [],
      isBRC20: false,
      isMore: false,
      isSupport: false,
      tokenDetail: {},
      tokenMarketInfo: {},
      overviewList1: [],
      overviewList2: [],
      token_id: undefined,
      // utc_type: '',
      projectInfoList: [],
      count: 0,
      defaultImg: 'this.src="https://tokenpocket.gz.bcebos.com/common.png"',
      token_status: 0,
      tokenInfoTabType: '',
      contract: '',
      swapMarketConfig: {},
      account_url: '',
      token_url: '',
      tradingRootDataType: 'Trading',
      hasRootData: false,
      teamList: [],
      originTeamList: [],
      investorsList: [],
      originInvestorsList: [],
      total_funding: null,
      rootDataLoading: false,
      isLoading: false,
      rune: null,
      runeStatus: '',
      baseInfoLabel: {
        supply: '',
        premine: '',
        burned: '',
        id: '',
        divisibility: '',
        etching: '',
      },
      minDataLabel: {
        status: '',
        etchBlock: '',
        currentBlock: '',
        startBlock: '',
        endBlock: '',
        perMintCount: '',
        mintTotalCount: '',
        mintedCount: '',
        laveCount: '',
      },
    };
  },
  computed: {
    ...mapGetters(['isDark', 'isMobileSize', 'isTon']),
    // isCurrentToken() {
    //   const token = this.resultList.find(item => item.address === this.contract)
    //   if (token) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    tokenInfoHeaderImg() {
      if (this.isRune) {
        return require(`../assets/rune/rune-bg${this.darkModeImg}.png`);
      } else {
        return require(`../assets/token-info/token-info-header${this.darkModeImg}.png`);
      }
    },
    noDataImg() {
      return require(`../assets/home/no-data${this.darkModeImg}.png`);
    },
    tradingRootDataTabList() {
      let list = [
        {
          tab: this.$t('root_data.tab_1'),
          type: 'Trading',
        },
        {
          tab: this.$t('root_data.tab_2'),
          type: 'Team-financing',
        },
      ];
      if (this.isBRC20) {
        list = list.slice(0, 1);
      }
      return list;
    },
    isRisk() {
      if (this.tokenDetail) {
        if (
          this.tokenDetail.token_status === 2 ||
          this.tokenDetail.token_status === 4
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    issuerImg() {
      let imgStatus = '';
      if (this.isDark) {
        imgStatus = '-dark';
      }
      return require(`../assets/copy-black${imgStatus}.png`);
    },
    isRiskyAttention() {
      if (this.risky === 0 && this.attention === 0 && this.isHoleAddress) {
        return false;
      } else {
        return true;
      }
    },
    tabList() {
      let list = [];
      let imgStatus = '';
      if (this.isDark) {
        imgStatus = '-dark';
      }
      const security = {
        icon: require(`../assets/tabs/Security${imgStatus}.png`),
        activeIcon: require(`../assets/tabs/Security-active.png`),
        title: this.$t('security'),
        type: 'security',
      };
      const holder_info = {
        icon: require(`../assets/tabs/Holders${imgStatus}.png`),
        activeIcon: require(`../assets/tabs/Holders-active.png`),
        title: this.$t('holder_info.title_1'),
        type: 'holder_info',
      };
      const trading_info = {
        icon: require(`../assets/tabs/Trading${imgStatus}.png`),
        activeIcon: require(`../assets/tabs/Trading-active.png`),
        title: this.$t('trading_info.title_1'),
        type: 'trading_info',
      };
      const root_data = {
        icon: require(`../assets/tabs/Team-Funding${imgStatus}.png`),
        activeIcon: require(`../assets/tabs/Team-Funding-active.png`),
        title: this.$t('root_data.tab_2'),
        type: 'root_data',
      };
      if (this.isBRC20) {
        list = [holder_info, trading_info];
      } else if (this.isSupport) {
        list = [security, holder_info, trading_info, root_data];
      } else {
        list = [holder_info, trading_info, root_data];
      }
      return list;
    },
    securityList() {
      if (this.risky === 0 && this.attention === 0) {
        const arr = [...this.contractList, ...this.tradeList, ...this.taxList];
        return arr.slice(0, 6);
      } else {
        const arr = [...this.tradeList, ...this.contractList, ...this.taxList];
        const arr1 = arr.filter((item) => item.status === 2);
        const arr2 = arr.filter((item) => item.status === 1);
        const arr3 = [...arr1, ...arr2];

        // return arr3.slice(0, 6)
        return [...arr1, ...arr2].slice(0, 6);
      }
    },
    loadingImg() {
      let imgStatus = '';
      if (this.isDark) {
        imgStatus = '-dark';
      }
      return require(`../assets/loading${imgStatus}.png`);
    },
    socialMap() {
      let imgStatus = '';
      if (this.isDark) {
        imgStatus = '-dark';
      }
      return {
        Website: require(`../assets/social-icon/04${imgStatus}.png`),
        Twitter: require(`../assets/social-icon/01${imgStatus}.png`),
        Telegram: require(`../assets/social-icon/03${imgStatus}.png`),
        Discord: require(`../assets/social-icon/02${imgStatus}.png`),
        Youtube: require(`../assets/social-icon/07${imgStatus}.png`),
        Instagram: require(`../assets/social-icon/06${imgStatus}.png`),
        Medium: require(`../assets/social-icon/08${imgStatus}.png`),
        EMail: require(`../assets/social-icon/09${imgStatus}.png`),
        Facebook: require(`../assets/social-icon/05${imgStatus}.png`),
      };
    },
    socialList() {
      const arr = [];
      for (const key in this.socialMap) {
        const arr2 = this.projectInfoList.filter((item) => item[key]);
        if (arr2.length > 0) {
          // console.log('obj', arr2[0])
          const obj = arr2[0] || {};
          obj.icon = this.socialMap[key];
          obj.url = arr2[0][key];
          arr.push(obj);
        }
      }
      return arr;
    },
  },

  watch: {
    isMobileSize() {
      this.parseData(this.originTeamList, this.originInvestorsList);
    },
    tabList: {
      handler(val) {
        this.tokenInfoTabType = this.tabList[0].type;
      },
      immediate: true,
    },
  },

  created() {
    document
      .getElementsByTagName('link')[0]
      .setAttribute('href', '/profile.ico');
    document.title = this.$t('tokenInfoTitle');
    let token_id = '';
    // console.log('location', location);
    let { tokenId, utc_type } = this.$route.params;
    // console.log('tokenId', tokenId);
    // console.log('utc_type', utc_type);
    const { id } = this.$route.query;
    // console.log('id', id);
    if (tokenId && utc_type) {
      if (tokenId) {
        token_id = tokenId;
      } else {
        token_id = id;
      }
    } else if (tokenId && !utc_type) {
      token_id = tokenId;
    } else {
      // 手动刷新页面
      let query = location.search.slice(1);
      let queryObj = {};
      if (query) {
        _.forEach(query.split('&'), (item) => {
          let tempArr = item.split('=');
          queryObj[tempArr[0]] = tempArr[1];
        });
      }
      // console.log('queryObj-token-info', queryObj);
      if (queryObj.token_id) {
        token_id = queryObj.token_id;
        utc_type = queryObj.utc_type || 'UTC+24';
      } else {
        token_id = id;
      }
    }
    if (!utc_type) {
      utc_type = this.getUTC();
    }

    if (utc_type === 'UTC+24') {
      utc_type = '';
    }

    this.token_id = Number(token_id);
    this.getTokenDetailInfo(token_id, utc_type);
    // this.getTokenDetail(token_id);
    this.getSwapMarketConfig();
  },
  mounted() {
    document.body.addEventListener('click', () => {
      this.searchStatus = '';
      this.resultList = [];
    });
  },

  methods: {
    getTradingInfo(info) {
      this.tradingInfo = info
      console.log('info', info);
    },
    async getBTCBlockHeight() {
      try {
        const response = await fetch('https://blockchain.info/q/getblockcount');
        return await response.json();
      } catch (error) {
        console.log(error);
      }
    },
    async getRune(contract) {
      this.runeStatus = '';
      try {
        const response = await fetch(`${TP_RUNE_DOMAIN}/rune/${contract}`, {
          headers: {
            Accept: 'application/json',
          },
        });
        const res = await response.json();
        // console.log('getRune', res);
        if (res) {
          const {
            premine,
            burned,
            etching,
            symbol,
            spaced_rune,
            terms,
            divisibility,
            block,
            mints,
          } = res.entry;
          if (res.entry) {
            this.rune = {
              symbol,
              name: spaced_rune,
              logo: this.tokenDetail.icon_url,
            };
            // console.log('rune', this.rune);
          }
          let supply = ''; // 总供应量
          let premineAmount = ''; // 预挖量
          let startBlock = ''; // 开始铸造区块
          let endBlock = ''; // 结束铸造区块
          let perMintCount = ''; // 每次铸造数量
          let mintTotalCount = ''; // 铸造总次数
          if (premine !== undefined && divisibility !== undefined) {
            premineAmount = premine / Math.pow(10, divisibility);
          }
          if (terms) {
            const { amount, cap, height, offset } = terms;
            if (
              mints !== undefined &&
              amount !== undefined &&
              divisibility !== undefined
            ) {
              supply =
                premineAmount + (mints * amount) / Math.pow(10, divisibility);
            }
            if (amount !== undefined && divisibility !== undefined) {
              perMintCount = amount / Math.pow(10, divisibility);
            }
            // 计算开始铸造区块
            if (height && height[0]) {
              startBlock = height[0];
            } else if (offset && offset[0] && !offset[1]) {
              startBlock = block + offset[0];
            } else if (height && height[1] && offset[1]) {
              startBlock = height[1] - offset[1];
            } else {
              startBlock = block;
            }
            // 计算结束铸造区块
            if (height && height[1]) {
              endBlock = height[1];
            } else {
              endBlock = 'Unlimited';
            }
            if (cap !== undefined) {
              mintTotalCount = cap;
            }
          }
          this.baseInfoLabel.supply = supply;
          this.baseInfoLabel.premine = premineAmount;
          this.baseInfoLabel.burned = burned;
          this.baseInfoLabel.id = res.id;
          this.baseInfoLabel.divisibility = divisibility;
          this.baseInfoLabel.etching = etching;

          this.minDataLabel.etchBlock = block;
          this.minDataLabel.startBlock = startBlock;
          this.minDataLabel.endBlock = endBlock;
          this.minDataLabel.perMintCount = perMintCount;
          this.minDataLabel.mintTotalCount = mintTotalCount;
          this.minDataLabel.mintedCount = mints;
          this.minDataLabel.laveCount = mintTotalCount - mints;

          this.isLoading = false;

          let BTCBlockHeight = (await this.getBTCBlockHeight()) || '-'; // BTC当前区块高度
          if (BTCBlockHeight !== '-') {
            if (BTCBlockHeight < startBlock) {
              this.runeStatus = 'not-started';
            } else if (BTCBlockHeight > endBlock) {
              this.runeStatus = 'over';
            } else {
              if (mintTotalCount === mints) {
                this.runeStatus = 'over';
              } else {
                this.runeStatus = 'pending';
              }
            }
          }
          console.log('runeStatus', this.runeStatus);

          this.minDataLabel.currentBlock = BTCBlockHeight;
          this.minDataLabel.status = this.runeStatus;
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    getToken(token) {
      // console.log('token', token);
      this.searchStatus = '';
      this.resultList = [];
      let utc_type = this.getUTC();
      this.getTokenDetailInfo(token.hid, utc_type);
    },
    getUTC() {
      let utc_type = '';
      // this.getTokenDetail();
      // 获取当前时区 时区偏差（time-zone offset）表示协调世界时（UTC）与本地时区之间的差值，单位为分钟。需要注意的是如果本地时区后于协调世界时，则该差值为正值，如果先于协调世界时则为负值。例如你所在时区为 UTC+10（澳大利亚东部标准时间），将会返回 -600。对于同一个时区，夏令时（Daylight Saving Time）将会改变这个值。
      let timezoneOffset = new Date().getTimezoneOffset() / 60;
      if (timezoneOffset < 0) {
        timezoneOffset = '+' + Math.abs(timezoneOffset);
      } else {
        timezoneOffset = '-' + Math.abs(timezoneOffset);
      }
      utc_type = `UTC${timezoneOffset}`;
      // console.log('utc_type', utc_type);
      return utc_type;
    },
    getSearchParams(params) {
      // console.log('params', params);
      const { inputVal, resultList, searchStatus } = params;
      this.inputVal = inputVal;
      this.resultList = resultList;
      this.searchStatus = searchStatus;
      this.resultList.forEach((item) => {
        if (
          item.address === this.contract &&
          item.blockchain_id === this.tokenDetail.blockchain_id
        ) {
          item.isSelected = true;
        }
      });
    },
    async onSearch() {
      let lang = 'en';
      if (this.$i18n.locale === 'zh') {
        lang = 'zh-Hans';
      }
      const data = await tokenSearch(this.inputVal, lang);
      // console.log('data', data);
      // this.resultList = [...data, ...data,...data,...data,...data,];
      this.resultList = data;
    },
    goUniSat() {
      window.open(`https://hk.unisat.io/brc20/${this.tokenDetail.contract}`);
    },
    async getRootData(contract_address) {
      this.rootDataLoading = true;
      this.hasRootData = true;
      try {
        const response = await fetch(
          `${TP_SERVER_DOMAIN}/v1/token/rootdata?contract_address=${contract_address}`
        );
        const res = await response.json();
        if (res && res.result === 0 && res.data && res.data.data) {
          this.originTeamList = res.data.data.team_members || [];
          // this.originTeamList = [];
          this.originInvestorsList = res.data.data.investors || [];
          // this.originInvestorsList = [];
          this.parseData(this.originTeamList, this.originInvestorsList);
          this.total_funding = res.data.data.total_funding;
        }
        this.rootDataLoading = false;
      } catch (error) {
        console.log('error', error);
        this.rootDataLoading = false;
      }
    },
    parseData(team_members, investors) {
      this.teamList = team_members.slice(0, 4);
      if (this.isMobileSize) {
        this.investorsList = investors.slice(0, 12);
      } else {
        this.investorsList = investors.slice(0, 8);
      }
    },
    showAll(type) {
      if (type === 'team') {
        this.teamList = this.originTeamList;
      } else {
        this.investorsList = this.originInvestorsList;
      }
    },
    onTradingRootDataTab(tab) {
      this.tradingRootDataType = tab.type;
      if (tab.type === 'Team-financing' && !this.hasRootData) {
        this.getRootData(this.contract);
        // this.getRootData('0x1f9840a85d5af5bf1d1762f925bdaddc4201f984')
      }
    },
    async getExplorerList(blockchain_id) {
      try {
        const response = await fetch(
          `${TP_SERVER_DOMAIN}/v1/explorer/list?blockchain_id=${blockchain_id}`
        );
        const res = await response.json();
        if (res.result === 0 && res.data && res.data[0]) {
          this.account_url = res.data[0].account_url;
          this.token_url = res.data[0].token_url;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getSwapMarketConfig() {
      try {
        const response = await fetch(
          `${TP_SERVER_DOMAIN}/v1/swap_market/config`
        );
        const res = await response.json();
        if (res.result === 0 && res.data) {
          this.swapMarketConfig = res.data;
        }
      } catch (error) {
        console.log('swap_market/config错误', error);
      }
    },
    goBlockchainBrowser(contract, blockchain_id) {
      this.onBlockchainBrowser(contract, blockchain_id);
    },
    getCopy(text, flag) {
      this.onCopy(text, flag);
    },
    onTab(type) {
      this.tokenInfoTabType = type;
      if (type === 'root_data' && !this.hasRootData) {
        this.getRootData(this.contract);
      }
    },
    changeMode() {
      let mode = document.documentElement.hasAttribute('theme');
      // console.log('mode', mode)
      setMode(!mode);
      this.$store.commit('setDark', !mode);
    },
    async getTokenDetail(token_id) {
      const lang = this.$i18n.locale === 'en' ? 'en' : 'zh-Hans';
      try {
        const response = await fetch(
          `${TP_SERVER_DOMAIN}/v1/tokenallbyid?id=${token_id}&lang=${lang}`
        );
        const res = await response.json();
        if (response.status === 200 && res.result === 0) {
          if (res.data && res.data.project_info) {
            let arr = JSON.parse(res.data.project_info);
            // console.log('arr', arr)
          }
          if (res.data && res.data.published) {
            res.data.published = dayjs(res.data.published)
              .utc()
              .format('YYYY-MM-DD');
          }

          this.tokenDetail = res.data || {};
          this.originDesc = res.data.description;
          this.address = res.data.address;
          if (res.data && res.data.blockchain_id) {
            // 判断代币所在的链是否支持安全检测
            this.isSupport = this.chainList.some(
              (item) => item.blockchain_id === res.data.blockchain_id
            );
            if (this.isSupport && this.address) {
              let address = this.address.trim();
              if (res.data.blockchain_id !== 10) {
                address = address.toLowerCase();
              }
              await this.tokenSecurity(address, res.data.blockchain_id);
              if (res.data.token_status === 2 || res.data.token_status === 4) {
                this.token_status = 2;
                const obj = {
                  label: this.$t('trade_token_status_text1'),
                  status: 2,
                };
                this.tradeList.push(obj);
              }
              // const isContract = await this.getCode(
              //   this.address,
              //   NodeMap[res.data.blockchain_id]
              // )
              // if (isContract) {
              // }
            }
          }
        }
      } catch (error) {
        // console.log('error', error)
        // console.log('msg', '未查询到该代币')
      }
    },
    async getTokenDetailInfo(id, utc_type) {
      this.overviewList1 = [];
      this.overviewList2 = [];
      this.tradeList = [];
      this.isLoading = true;
      this.rune = null;
      const lang = this.$i18n.locale === 'en' ? 'en' : 'zh-Hans';
      try {
        const response = await fetch(
          `${TP_SERVER_DOMAIN}/v1/token/detail_info?id=${id}&lang=${lang}`,
          {
            headers: {
              utc_type,
            },
          }
        );
        const res = await response.json();
        if (response.status === 200 && res.result === 0) {
          if (res.data && res.data.project_info) {
            let arr = JSON.parse(res.data.project_info);
            this.projectInfoList = arr;
          }
          if (res.data && res.data.issue_time && res.data.issue_time != '0') {
            res.data.issue_time =
              dayjs
                .utc(res.data.issue_time * 1000)
                .format('YYYY-MM-DD HH:mm:ss') + ' (UTC)';
          }

          if (res.data && res.data.chg) {
            let chg = new Big(res.data.chg * 100);
            res.data.chg = chg.round(2).toString();
          }

          if (
            res.data &&
            res.data.chain_id === '-239' &&
            res.data.ns === 'ton'
          ) {
            this.$store.commit('setTon', true);
          } else {
            this.$store.commit('setTon', false);
          }

          this.tokenDetail = res.data || {};
          this.tokenDetail.price_usd = parsePrice(
            parseFloat(this.tokenDetail.price_usd || 0)
          );
          if (res.data && res.data.token_market_info) {
            this.createOverviewList(res.data);
          }
          if (res.data && res.data.token_protocol === 3) {
            this.isBRC20 = true;
            this.createOverviewBRC20List(res.data);
          } else {
            this.isBRC20 = false;
          }
          if (res.data && res.data.blockchain_id) {
            this.getExplorerList(res.data.blockchain_id);
          }
          // if (res.data && res.data.contract) {
          //   this.getRootData(res.data.contract);
          // }
          this.originDesc = res.data.description;
          this.contract = res.data.contract;
          if (res.data && res.data.token_protocol === 7) {
            this.isRune = true;
            this.isLoading = true;
            this.getRune(this.contract);
          } else {
            this.isRune = false;
          }
          if (res.data && res.data.blockchain_id) {
            // 判断代币所在的链是否支持安全检测
            this.isSupport = this.chainList.some(
              (item) => item.blockchain_id === res.data.blockchain_id
            );
            if (this.isSupport && this.contract) {
              let contract = this.contract.trim();
              if (res.data.blockchain_id !== 10) {
                contract = contract.toLowerCase();
              }
              await this.tokenSecurity(contract, res.data.blockchain_id);
              if (res.data.token_status === 2 || res.data.token_status === 4) {
                this.token_status = 2;
                const obj = {
                  label: this.$t('trade_token_status_text1'),
                  status: 2,
                };
                this.tradeList.push(obj);
              }
              // const isContract = await this.getCode(
              //   this.address,
              //   NodeMap[res.data.blockchain_id]
              // )
              // if (isContract) {
              // }
            }
          }
          this.isLoading = false;
          this.changeURLArg({
            ns: res.data.ns,
            chain_id: res.data.chain_id,
            blockchain_id: res.data.blockchain_id,
            address: res.data.contract,
            id: id,
          });
        }
      } catch (error) {
        this.isLoading = false;
        console.log('error', error);
        // console.log('msg', '未查询到该代币')
      }
    },
    createOverviewList(token) {
      let tokenMarketInfo = token.token_market_info;
      for (const key in tokenMarketInfo) {
        let obj = {
          title: this.$t('overview.' + key),
          amount: tokenMarketInfo[key],
        };
        switch (key) {
          case 'max_supply':
            obj.amount = Number(obj.amount).toLocaleString('en-US');
            obj.order = 1;
            this.overviewList1.push(obj);
            break;
          case 'total_minted':
            if (this.isTon) {
              obj.amount = Number(obj.amount).toLocaleString('en-US');
              obj.order = 2;
              this.overviewList1.push(obj);
            }
            break;
          case 'circulating_supply':
            obj.amount = Number(obj.amount).toLocaleString('en-US');
            if (this.isTon) {
              obj.order = 1
              this.overviewList2.push(obj);
            } else {
              obj.order = 3;
              this.overviewList1.push(obj);
            }
            break;
          case 'total_market_cap':
            obj.amount = '$' + Number(obj.amount).toLocaleString('en-US');
            obj.order = 2;
            this.overviewList2.push(obj);
            break;
          case 'circulating_market_cap':
            obj.amount = '$' + Number(obj.amount).toLocaleString('en-US');
            obj.order = 3;
            this.overviewList2.push(obj);
            break;
          case 'holders':
            obj.amount = Number(obj.amount).toLocaleString('en-US');
            obj.order = 4;
            this.overviewList2.push(obj);
            break;
          default:
            break;
        }
      }
      this.overviewList1.sort((a, b) => a.order - b.order);
      this.overviewList2.sort((a, b) => a.order - b.order);
    },
    createOverviewBRC20List(token) {
      let tokenMarketInfo = token.token_market_info;
      if (token.token_protocol === 3) {
        let percent = 0;
        const { total_minted, max_supply } = tokenMarketInfo;
        if (max_supply) {
          percent = truncateDecimals((total_minted / max_supply) * 100, 2);
        }
        let brc20Obj1 = {
          isBRC20: true,
          symbol: token.contract,
          title: this.$t('overview.status'),
          percent,
          btnText:
            percent === 100
              ? this.$t('overview.finished')
              : this.$t('overview.mint'),
        };
        this.overviewList1.push(brc20Obj1);
        // 如果没有交易数则不显示
        if (token.tx_count) {
          let brc20Obj2 = {
            title: this.$t('overview.total_transactions'),
            amount: Number(token.tx_count).toLocaleString('en-US'),
          };
          this.overviewList2.push(brc20Obj2);
        }
      }
    },
    changeURLArg(obj) {
      let index = location.href.indexOf('#/');
      let query = location.href.slice(index + 13);
      let queryObj = {};
      if (query) {
        _.forEach(query.split('&'), (item) => {
          let tempArr = item.split('=');
          queryObj[tempArr[0]] = tempArr[1];
        });
      }
      queryObj = { ...queryObj, ...obj };

      const newQuery = Object.keys(queryObj)
        .filter((key) => queryObj[key])
        .map((key) => `${key}=${queryObj[key]}`)
        .join('&');

      history.replaceState(
        '',
        '',
        `${location.search}#/token-info?${newQuery}`
      );
    },
    report() {
      let lang = this.$i18n.locale === 'en' ? 'en' : 'zh';
      const { icon_url, symbol, address, blockchain_id } = this.tokenDetail;
      location.href = `${DOMAIN}?locale=${lang}#/token?logo=${icon_url}&symbol=${symbol}&contract=${address}&blockchainId=${blockchain_id}`;
    },
    showMore() {
      this.isMore = true;
    },
    goTokenDetection() {
      const { contract, blockchain_id } = this.tokenDetail;
      // ios safari 不支持直接使用 window.open
      // window.open(
      //   `https://tokensecurity.tokenpocket.pro/?locale=${this.$i18n.locale}&utm_source=tokenpocket#/?address=${address}&blockchain_id=${blockchain_id}`
      // )
      const chain = this.chainList.filter(
        (item) => item.blockchain_id === blockchain_id
      );
      window.location.href = `https://tokensecurity.tptool.pro/?locale=${this.$i18n.locale}&utm_source=tokenpocket#/?address=${contract}&ns=${chain[0].ns}&chain_id=${chain[0].chain_id}&blockchain_id=${blockchain_id}`;
    },
    changeLang() {
      // if (this.$i18n.locale === 'en') {
      //   this.$i18n.locale = 'zh';
      // } else {
      //   this.$i18n.locale = 'en';
      // }
    },
    goSocial(item) {
      if (item['EMail']) {
        this.onCopy(item.url);
      } else {
        window.open(item.url, '_self');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../style/token-info.scss';
</style>
