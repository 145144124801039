<template>
  <div class="overview content">
    <div class="text title">{{ $t('overview.title') }}</div>
    <div class="line-wrap">
      <div class="line"></div>
    </div>
    <div class="overview-list">
      <OverviewItem
        v-for="(item, index) in overviewList1"
        :key="index"
        :overview="item"
      />
    </div>
    <div class="overview-list overview-list2" :class="{ ton: isTon }">
      <OverviewItem
        v-for="(item, index) in overviewList2"
        :key="index"
        :overview="item"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OverviewItem from './OverviewItem.vue';
export default {
  props: {
    overviewList1: {
      type: Array,
    },
    overviewList2: {
      type: Array,
    },
  },
  computed: { ...mapGetters(['isTon']) },
  components: { OverviewItem },
};
</script>

<style lang="scss" scoped>
.overview {
  margin-top: 0.32rem;
  .line-wrap {
    margin: 0 0.32rem;
  }
  .overview-list {
    margin: 0.32rem 0.32rem 0;
    display: grid;
    gap: 0.1rem 0;
  }
  .overview-list2 {
    margin-top: 0.1rem;
    grid-template-columns: 1fr 1fr;
    gap: 0.1rem;
    &.ton {
      grid-template-columns: 1fr;
    }
  }
}
@media screen and (min-width: 767px) {
  .overview {
    margin-top: 0;
    border: none;
    box-shadow: none;
    border-radius: 0;
    background: none;
    padding-bottom: 0.2rem;
    .line-wrap {
      display: none;
    }
    .overview-list {
      margin: 0 0.12rem;
      gap: 0.12rem 0;
    }
    .overview-list2 {
      margin-top: 0.12rem;
      gap: 0.12rem;
    }
  }
}
</style>
