<template>
  <div class="tools">
    <div class="tools-title" :class="{ brc20: isBRC20 }">
      <span>{{ $t('tools.title') }}</span>
    </div>
    <div class="tools-list" :class="{ brc20: isBRC20 }">
      <a
        class="tools-item"
        :href="item.url"
        v-for="(item, index) in toolsList"
        :key="index"
      >
        <div class="tools-logo">
          <img :src="item.logo" alt=""/>
        </div>
        <div class="tools-name">
          <span>{{ item.title }}</span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isBRC20: {
      type: Boolean
    }
  },
  computed: {
    isZH() {
      if (this.$i18n.locale === 'zh') {
        return true;
      } else {
        return false;
      }
    },
    urlLocaleLang() {
      if (this.isZH) {
        return '?locale=zh';
      } else {
        return '?locale=en';
      }
    },
    toolsList() {
      if (this.isBRC20) {
        return [
          {
            title: this.$t('tools.tool_5'),
            logo: require(`../assets/tools/tools-5.png`),
            url: `https://tp-lab.tptool.pro/insc/${this.urlLocaleLang}#/`,
          },
          {
            title: this.$t('tools.tool_6'),
            logo: require(`../assets/tools/tools-6.png`),
            url: `https://tp-lab.tptool.pro/nostr/${this.urlLocaleLang}#/`,
          },
          {
            title: this.$t('tools.tool_4'),
            logo: require(`../assets/tools/tools-7.png`),
            url: `https://verify.tpwallet.io/${this.urlLocaleLang}#/`,
          },
        ];
      } else {
        return [
          {
            title: this.$t('tools.tool_1'),
            logo: require(`../assets/tools/tools-1.png`),
            url: `https://tokensecurity.tptool.pro/${this.urlLocaleLang}#/`,
          },
          {
            title: this.$t('tools.tool_2'),
            logo: require(`../assets/tools/tools-2.png`),
            url: `https://approval.tptool.pro/${this.urlLocaleLang}#/`,
          },
          {
            title: this.$t('tools.tool_3'),
            logo: require(`../assets/tools/tools-3.png`),
            url: `https://batchsender.tptool.pro/${this.urlLocaleLang}#/`,
          },
          {
            title: this.$t('tools.tool_4'),
            logo: require(`../assets/tools/tools-4.png`),
            url: `https://verify.tpwallet.io/${this.urlLocaleLang}#/`,
          },
        ];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tools {
  margin: 0.5rem 0.64rem 0;
  .tools-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.brc20 {
      justify-content: center;
    }
    span {
      font-size: 0.28rem;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: var(--gray-1);
    }
  }
  .tools-list {
    margin-top: 0.32rem;
    display: flex;
    justify-content: space-between;
    &.brc20 {
      justify-content: space-evenly;
    }
    .tools-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 20%;
      .tools-logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 0.96rem;
        height: 0.96rem;
        // background: var(--gray-1);
        border-radius: 0.24rem;
        img {
          border-radius: 0.24rem;
          width: 100%;
          height: 100%;
        }
      }
      .tools-name {
        margin-top: 0.16rem;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-size: 0.22rem;
          font-family: PingFang SC, PingFang SC-Regular;
          color: var(--gray-1);
          text-align: center;
        }
      }
    }
  }
}
@media screen and (min-width: 767px) {
  .tools {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .tools-title {
      span {
        font-size: 0.18rem;
        transform: scale(0.9);
      }
    }
    .tools-list {
      flex-direction: row;
      margin-top: 0.3rem;
      .tools-item {
        width: 0.8rem;
        &:nth-child(n + 2) {
          margin-top: 0;
          margin-left: 0.2rem;
        }
        .tools-logo {
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 0.12rem;
          img {
            border-radius: 0.12rem;
          }
        }
        .tools-name {
          margin-top: 0.04rem;
          span {
            font-size: 0.18rem;
            transform: scale(0.9);
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1400px) {
  .tools {
    margin: 0 0 0 6.15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tools-title {
      span {
        font-size: 0.12rem;
        transform: scale(0.9);
      }
    }
    .tools-list {
      flex-direction: column;
      margin-top: 0.16rem;
      margin-left: 0;
      .tools-item {
        width: auto;
        &:nth-child(n + 2) {
          margin-top: 0.16rem;
          margin-left: 0;
        }
        .tools-logo {
          width: 0.4rem;
          height: 0.4rem;
          border-radius: 0.12rem;
          img {
            border-radius: 0.12rem;
          }
        }
        .tools-name {
          margin-top: 0.04rem;
          span {
            font-size: 0.12rem;
            transform: scale(0.9);
          }
        }
      }
    }
  }
}
</style>
