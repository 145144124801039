<template>
  <div class="holders content">
    <div class="holders-wrap" v-if="holders">
      <div class="all-holders">
        <div class="title-wrap">
          <span class="text title">{{ $t('holder_info.title_2') }}</span>
          <span class="holders-count"
            >({{ $t('holder_info.title_3') }} {{ holders.holder_count }})</span
          >
        </div>
        <div ref="charts" id="charts-container"></div>
        <div class="line-wrap">
          <div class="line"></div>
        </div>
        <div class="percent-bar">
          <div
            class="top-1-10"
            :style="{ width: holders.top_1_10_percent + '%' }"
          ></div>
          <div
            class="top-11-50"
            :style="{ width: holders.top_11_50_percent + '%' }"
          ></div>
          <div
            class="top-51-100"
            :style="{ width: holders.top_51_100_percent + '%' }"
          ></div>
          <div
            class="others"
            :style="{ width: holders.others_percent + '%' }"
          ></div>
        </div>
        <div class="top-list">
          <TopItem
            v-for="(item, index) in topList"
            :key="index"
            :topObj="item"
          />
        </div>
      </div>
      <div class="top-100-holders">
        <div class="top-list-tabs">
          <div class="tab-title">{{ $t('holder_info.title_4') }}</div>
          <Tabs
            :tabList="tabList"
            :curType="tokenTopType"
            @getTab="onTab"
            holders
          />
        </div>
        <div class="line-wrap">
          <div class="line"></div>
        </div>
        <div class="top-100-holders-list">
          <div class="top-100-holders-header">
            <div class="order">#</div>
            <div class="value">
              {{ $t('holder_info.text_1') }}({{ this.symbol }})
            </div>
            <div class="percent">{{ $t('holder_info.text_2') }}</div>
            <div class="address">{{ $t('holder_info.text_3') }}</div>
          </div>
          <div class="line"></div>
          <div class="top-100-holders-content">
            <Top100Item
              v-for="(item, index) in curTopHoldersList"
              :key="index"
              :top100Item="item"
              :blockchain_id="blockchain_id"
              :account_url="account_url"
              :isBRC20="isBRC20"
              :contract="contract"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="loading-content" v-else>
      <Loading />
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { TP_SERVER_DOMAIN } from '../utils/config';
import { parseWithUnit, truncateDecimals } from '../utils';
import TopItem from './TopItem.vue';
import Tabs from './Tabs.vue';
import Top100Item from './Top100Item.vue';
import Loading from './Loading.vue';
import { mapGetters } from 'vuex';

export default {
  props: {
    blockchain_id: {
      type: Number || String,
    },
    contract: {
      type: String,
    },
    symbol: {
      type: String,
    },
    account_url: {
      type: String,
    },
    isBRC20: {
      type: Boolean,
    },
  },
  components: { TopItem, Tabs, Top100Item, Loading },
  data() {
    return {
      holders: null,
      tokenTopType: '',
      charts: null,
      bgColor: '#ffffff',
      barBgColor: 'rgba(220, 220, 220, 0.2)',
      gridColor: '#eeeeee',
      fontSizeObj: {
        fontSize1: 12,
        fontSize2: 12,
      }
    };
  },
  computed: {
    ...mapGetters(['isDark']),
    curTopHoldersList() {
      if (this.holders.holder_infos) {
        if (this.tokenTopType === 'top-20') {
          return this.holders.holder_infos.slice(0, 20);
        } else if (this.tokenTopType === 'top-50') {
          return this.holders.holder_infos.slice(0, 50);
        } else {
          return this.holders.holder_infos;
        }
      } else {
        return [];
      }
    },
    tabList() {
      return [
        {
          title: 'Top 20',
          type: 'top-20'
        },
        {
          title: 'Top 50',
          type: 'top-50'
        },
        {
          title: 'Top 100',
          type: 'top-100'
        },
      ];
    },
    topList() {
      return [
        {
          title: 'Top 1~10',
          value: this.holders.top_1_10,
          color: 'red',
          symbol: this.symbol,
          percent: this.holders.top_1_10_percent,
        },
        {
          title: 'Top 11~50',
          value: this.holders.top_11_50,
          color: 'yellow',
          symbol: this.symbol,
          percent: this.holders.top_11_50_percent,
        },
        {
          title: 'Top 51~100',
          value: this.holders.top_51_100,
          color: 'green',
          symbol: this.symbol,
          percent: this.holders.top_51_100_percent,
        },
        {
          title: this.$t('holder_info.text_4'),
          value: this.holders.others,
          color: 'blue',
          symbol: this.symbol,
          percent: this.holders.others_percent,
        },
      ];
    },
  },
  watch: {
    isDark: {
      handler(val) {
        this.charts.dispose();
        if (val) {
          this.charts = echarts.init(this.$refs.charts, 'dark');
          this.barBgColor = 'rgba(0, 0, 0, 0.3)';
          this.bgColor = '#18191C';
          this.gridColor = '#2D2F33';
        } else {
          this.charts = echarts.init(this.$refs.charts, 'light');
          this.barBgColor = 'rgba(220, 220, 220, 0.2)';
          this.bgColor = '#ffffff';
          this.gridColor = '#eeeeee';
        }
        this.setChartsOption(
          this.holders,
          this.bgColor,
          this.barBgColor,
          this.gridColor,
          this.fontSizeObj
        );
      },
    },
    tabList: {
      handler(val) {
        this.tokenTopType = this.tabList[0].type
      },
      immediate: true
    },
    contract(nVal) {
      this.getHolderInfo(this.blockchain_id, nVal)
    },
  },
  created() {
    this.getHolderInfo(this.blockchain_id, this.contract);
  },
  mounted() {
    this.$nextTick(() => {
      // this.charts = echarts.init(this.$refs.charts);
      // this.charts.setOption(this.chartOption);
      // this.charts.resize();
    });
    window.onresize = () => {
      };
    window.addEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      if (
        document.documentElement.clientWidth > 767 &&
        document.documentElement.clientWidth <= 1400
      ) {
        this.fontSizeObj.fontSize1 = 9
        this.fontSizeObj.fontSize2 = 10
      } else {
        this.fontSizeObj.fontSize1 = 12
        this.fontSizeObj.fontSize2 = 12
      }
      this.$store.commit(
        'setMobileSize',
        document.documentElement.clientWidth <= 767
      );
      this.setChartsOption(this.holders, this.bgColor, this.barBgColor, this.gridColor, this.fontSizeObj)
    },
    onTab(type) {
      this.tokenTopType = type;
    },
    async getHolderInfo(blockchain_id, contract) {
      try {
        const response = await fetch(
          `${TP_SERVER_DOMAIN}/v1/token/holder_info?blockchain_id=${blockchain_id}&address=${contract}`
        );
        const res = await response.json();
        if (res.result === 0 && res.data) {
          let data = res.data;
          data.total_supply = truncateDecimals(data.total_supply, 3);
          data.top_1_10 = truncateDecimals(data.top_1_10, 2);
          data.top_11_50 = truncateDecimals(data.top_11_50, 2);
          data.top_51_100 = truncateDecimals(data.top_51_100, 2);
          data.others =
            data.total_supply -
            (data.top_1_10 + data.top_11_50 + data.top_51_100);
          data.top_1_10_percent = (
            (data.top_1_10 / data.total_supply) *
            100
          ).toFixed(2);
          data.top_11_50_percent = (
            (data.top_11_50 / data.total_supply) *
            100
          ).toFixed(2);
          data.top_51_100_percent = (
            (data.top_51_100 / data.total_supply) *
            100
          ).toFixed(2);
          data.others_percent = (
            (data.others / data.total_supply) *
            100
          ).toFixed(2);
          data.top_1_10 = parseWithUnit(truncateDecimals(data.top_1_10, 2));
          data.top_11_50 = parseWithUnit(truncateDecimals(data.top_11_50, 2));
          data.top_51_100 = parseWithUnit(truncateDecimals(data.top_51_100, 2));
          data.others = parseWithUnit(truncateDecimals(data.others, 2));
          if (data.holder_infos && Array.isArray(data.holder_infos)) {
            data.holder_infos.forEach((item) => {
              item.percent = ((item.amount / data.total_supply) * 100).toFixed(
                2
              );
              item.amount = parseWithUnit(parseFloat(item.amount));
            });
          }
          data.holder_count = Number(data.holder_count).toLocaleString('en-US');
          this.holders = data;
          if (this.isDark) {
            this.barBgColor = 'rgba(0, 0, 0, 0.3)';
            this.bgColor = '#18191C';
            this.gridColor = '#2D2F33';
          } else {
            this.barBgColor = 'rgba(220, 220, 220, 0.2)';
            this.bgColor = '#ffffff';
            this.gridColor = '#eeeeee';
          }
          this.$nextTick(() => {
            this.charts = echarts.init(this.$refs.charts, this.isDark ? 'dark' : 'light');
            this.setChartsOption(
              data,
              this.bgColor,
              this.barBgColor,
              this.gridColor,
              this.fontSizeObj
            );
            window.addEventListener('resize', () => {
              this.charts.resize();
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    setChartsOption(data, bgColor, barBgColor, gridColor, fontSizeObj) {
      const seriesData = [
        data.top_1_10_percent,
        data.top_11_50_percent,
        data.top_51_100_percent,
        data.others_percent,
      ];
      const option = {
        backgroundColor: bgColor,
        xAxis: {
          // type: 'category',
          data: ['', '', '', ''],
        },
        yAxis: {
          type: 'value',
          min: 0,
          max: 100,
          interval: 25,
          axisLabel: {
            fontSize: fontSizeObj.fontSize1,
            formatter: function (value) {
              if (value === 0) {
                return '0%';
              } else if (value > 0 && value <= 25) {
                return '25%';
              } else if (value > 25 && value <= 50) {
                return '50%';
              } else if (value > 50 && value <= 75) {
                return '75%';
              } else if (value > 75 && value <= 100) {
                return '100%';
              }
            },
          },
          splitLine: {
            lineStyle: {
              color: gridColor,
            },
          },
        },
        series: [
          {
            data: seriesData,
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: barBgColor,
              // color: 'rgba(180, 180, 180, 0.2)'
            },
            label: {
              show: true,
              position: 'top', // 在上方显示
              formatter: function (params) {
                // dataIndex是当前柱状图的索引
                return (
                  seriesData[params.dataIndex] + '%' // 此处return的字符串可根据自身项目需求自定义
                );
              },
            },
            textStyle: {
              color: '#333333', // 顶部数据的颜色
              fontSize: fontSizeObj.fontSize2, // 顶部数据的字体大小
            },
            barWidth: 28, // 柱子宽度
            itemStyle: {
              normal: {
                barBorderRadius: [50, 50, 0, 0], // （顺时针左上，右上，右下，左下）
                color: function (params) {
                  // build a color map as your need.
                  var colorList = ['#F5454D', '#FCB244', '#E7F06E', '#2980FE'];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
        grid: {
          // show: true,
          // borderColor: '#2D2F33',
          top: '10%',
          right: '0',
          left: '19%',
          bottom: '10%',
        },
      };
      if (this.charts) {
        this.charts.setOption(option);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.holders {
  margin-top: 0.11rem;
  .holders-wrap {
    .all-holders {
      .title-wrap {
        display: flex;
        align-items: center;
        .holders-count {
          margin-left: 0.2rem;
          font-size: 0.26rem;
          font-family: PingFang SC, PingFang SC-Regular;
          color: var(--gray-12);
        }
      }
      #charts-container {
        display: none;
      }
      .line-wrap {
        margin: 0 0.32rem;
      }
      .percent-bar {
        margin: 0.32rem 0.32rem 0;
        display: flex;
        align-items: center;
        .top-1-10 {
          height: 0.08rem;
          background: #f5454d;
          border-radius: 0.12rem 0 0 0.12rem;
        }
        .top-11-50 {
          height: 0.08rem;
          background: #fcb244;
        }
        .top-51-100 {
          height: 0.08rem;
          background: #e7f06e;
        }
        .others {
          height: 0.08rem;
          background: #2980fe;
          border-radius: 0 0.12rem 0.12rem 0;
        }
      }
      .top-list {
        margin: 0.34rem 0.32rem 0;
        .top-item:nth-child(n + 2) {
          margin-top: 0.32rem;
        }
      }
    }
    .top-100-holders {
      margin-top: 0.7rem;
      .line-wrap {
        margin: 0 0.32rem;
      }
      .top-list-tabs {
        margin: 0 0.32rem 0.27rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .tab-title {
          font-size: 0.28rem;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: var(--gray-1);
        }
      }
      .top-100-holders-list {
        margin: 0.36rem 0.32rem 0;
        div {
          font-size: 0.22rem;
          font-family: PingFang SC, PingFang SC-Regular;
          color: var(--gray-11);
        }
        .top-100-holders-header {
          display: flex;
          .order {
            flex: 0.5;
          }
          .value {
            flex: 1.3;
          }
          .percent {
            flex: 0.7;
          }
          .address {
            flex: 1.5;
            text-align: right;
          }
        }
        .line {
          display: none;
        }
        .top-100-holders-content {
          margin-top: 0.36rem;
          .top-100-item:nth-child(n + 2) {
            margin-top: 0.36rem;
          }
        }
      }
    }
  }
  .loading-content {
    display: block;
  }
}
@media screen and (min-width: 767px) {
  .holders {
    margin: 0;
    padding-bottom: 0.2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .holders-wrap {
      display: flex;
      width: 100%;
      .all-holders {
        width: 3.16rem;
        #charts-container {
          display: block;
          width: 100%;
          height: 2rem;
          margin-left: 0.01rem;
        }
        .title-wrap {
          align-items: center;
          .title {
            font-size: 0.18rem;
          }
          .holders-count {
            font-size: 0.16rem;
            font-family: PingFang SC, PingFang SC-Regular;
            padding: 0.2rem 0 0.16rem;
            margin-left: 0.07rem;
          }
        }
        .line-wrap {
          display: none;
        }
        .percent-bar {
          margin: 0.16rem 0 0 0.2rem;
        }
        .top-list {
          margin: 0.24rem 0 0 0.2rem;
          .top-item:nth-child(n + 2) {
            margin-top: 0.24rem;
          }
        }
      }
      .top-100-holders {
        flex: 1;
        margin: 0.2rem 0.2rem 0 0.4rem;
        .top-list-tabs {
          margin: 0;
          .tab-title {
            font-size: 0.18rem;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            color: var(--gray-15);
          }
        }
        .line-wrap {
          display: none;
        }
        .top-100-holders-list {
          margin: 0.15rem 0 0;
          padding: 0.16rem 0;
          border: 0.01rem solid var(--border-3);
          border-radius: 0.12rem;
          div {
            font-size: 0.15rem;
          }
          .top-100-holders-header {
            padding: 0 0.16rem;
          }
          .line {
            display: block;
            margin-top: 0.16rem;
          }
          .top-100-holders-content {
            padding: 0 0.16rem;
            margin-top: 0.17rem;
            height: 3.5rem;
            overflow: auto;
            scrollbar-width: none;
            &::-webkit-scrollbar {
              display: none;
            }
            .top-100-item:nth-child(n + 2) {
              margin-top: 0.16rem;
            }
          }
        }
      }
    }
    .loading-content {
      width: 100%;
    }
  }
}
@media screen and (min-width: 1400px) {
  .holders {
    margin: 0;
    padding-bottom: 0.2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .holders-wrap {
      display: flex;
      width: 100%;
      .all-holders {
        width: 3.16rem;
        #charts-container {
          display: block;
          width: 100%;
          height: 2rem;
        }
        .title-wrap {
          align-items: center;
          .holders-count {
            font-size: 0.14rem;
            font-family: PingFang SC, PingFang SC-Regular;
            padding: 0.2rem 0 0.16rem;
            margin-left: 0.07rem;
          }
        }
        .line-wrap {
          display: none;
        }
        .percent-bar {
          margin: 0.16rem 0 0 0.2rem;
        }
        .top-list {
          margin: 0.24rem 0 0 0.2rem;
          .top-item:nth-child(n + 2) {
            margin-top: 0.24rem;
          }
        }
      }
      .top-100-holders {
        flex: 1;
        margin: 0.2rem 0.2rem 0 0.4rem;
        .top-list-tabs {
          margin: 0;
          .tab-title {
            font-size: 0.16rem;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            color: var(--gray-15);
          }
        }
        .line-wrap {
          display: none;
        }
        .top-100-holders-list {
          margin: 0.15rem 0 0;
          padding: 0.16rem 0;
          border: 0.01rem solid var(--border-3);
          border-radius: 0.12rem;
          div {
            font-size: 0.13rem;
          }
          .top-100-holders-header {
            padding: 0 0.16rem;
          }
          .line {
            display: block;
            margin-top: 0.16rem;
          }
          .top-100-holders-content {
            padding: 0 0.16rem;
            margin-top: 0.17rem;
            height: 3.5rem;
            overflow: auto;
            scrollbar-width: none;
            &::-webkit-scrollbar {
              display: none;
            }
            .top-100-item:nth-child(n + 2) {
              margin-top: 0.16rem;
            }
          }
        }
      }
    }
    .loading-content {
      width: 100%;
    }
  }
}
</style>
